import React, { useLayoutEffect } from 'react';
import { LayoutsPageParams, useLayouts, useLayoutsRouter } from '../../../hooks';
import {
  withLayoutSelectedCountries,
  withLayoutsType,
  withSelectedContentPagePath,
  withSelectedLayoutId,
  withSelectedLayout,
  withSelectedMenuPagePath,
  withSelectedSpecialPagePath,
  withSelectedPromoPagePath,
  withAllLayouts,
  withLayoutInfo
} from '../../../state/Layouts';
import { useParams } from 'react-router-dom';
import { ContentListUiModuleBody, LayoutType, Page } from '../../../API';
import { useData } from '../../../data-layer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { validCountryCodeFilter } from '../../../utils/atomHelpers';
import { COLLECTION_ID_PARAM } from '../../../utils/consts/uiModules';
import { cloneDeep, isEqual } from 'lodash-es';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { countriesToParam, pageParamToPath, pagePathToParam } from '../../../utils/layouts';

const noPagePath = '/no-page';

export function LayoutsRouter(): React.ReactElement {
  const { getLayouts } = useLayouts();
  const { updateLayoutsRoute } = useLayoutsRouter();
  const { hasReadPermission } = usePermissions();

  const {
    pages: {
      state: { withRecordBucket: withPagesByType, withSelected: withSelectedPage },
      hook: { getBucket: getPagesByType, setSelected: setSelectedPage }
    },
    collections: {
      hook: { getLatestPublishedByIds: getLatestPublishedCollectionsByIds }
    }
  } = useData();

  const selectedPage = useRecoilValue(withSelectedPage);

  const [layoutsType, setLayoutsType] = useRecoilState(withLayoutsType);
  const [countryFilter, setCountryFilter] = useRecoilState(withLayoutSelectedCountries);
  const [selectedLayoutId, setSelectedLayoutId] = useRecoilState(withSelectedLayoutId);
  const [selectedLayout, setSelectedLayout] = useRecoilState(withSelectedLayout);
  const layoutInfo = useRecoilValue(withLayoutInfo);
  const allLayouts = useRecoilValue(withAllLayouts);

  const setSelectedContentPagePath = useSetRecoilState(withSelectedContentPagePath);
  const setSelectedMenuPagePath = useSetRecoilState(withSelectedMenuPagePath);
  const setSelectedSpecialPagePath = useSetRecoilState(withSelectedSpecialPagePath);
  const setSelectedPromosPagePath = useSetRecoilState(withSelectedPromoPagePath);

  const pagePages = useRecoilValue(withPagesByType(LayoutType.PAGE));
  const menuPages = useRecoilValue(withPagesByType(LayoutType.MENU));
  const specialPages = useRecoilValue(withPagesByType(LayoutType.SPECIAL));
  const promoPages = useRecoilValue(withPagesByType(LayoutType.PROMOS));

  const pages: Record<LayoutType, Page[] | undefined> = {
    [LayoutType.PAGE]: pagePages,
    [LayoutType.MENU]: menuPages,
    [LayoutType.SPECIAL]: specialPages,
    [LayoutType.PROMOS]: promoPages
  };

  const { pageUrl, countries, layoutId, type } = useParams<LayoutsPageParams>();

  useLayoutEffect(() => {
    for (const layoutType in LayoutType) {
      getPagesByType((LayoutType as Record<string, LayoutType>)[layoutType]);
    }
  }, []);

  useLayoutEffect(() => {
    // None of these params should ever be undefined as long as we're on the layouts page
    if (!pageUrl || !countries || !layoutId || !type) return;

    // First check if the countries are valid and replace the route if they are not
    const validatedCountries = validateCountries(countries);
    const countriesFromParam = countries.split('-');
    if (!isEqual(validatedCountries, countriesFromParam)) {
      updateLayoutsRoute({ countries: countriesToParam(validatedCountries) }, true);
      return;
    }

    // Check the layouts type param and update it in recoil if it has changed
    if (layoutsType !== type) {
      setLayoutsType(type);
    }

    // Check the countries param and update it in recoil if it has changed
    const countriesChanged = !isEqual(validatedCountries, countryFilter);
    if (countriesChanged) {
      setCountryFilter(validatedCountries);
    }

    setSelectedLayoutId(layoutId);

    // Validate PAGE from the pageUrl param
    const navigatedPagePath = pageParamToPath(pageUrl);
    const page = validateAndUpdateSelectedPage(navigatedPagePath);
    if (page) {
      getLayouts(page, type, validatedCountries, layoutId);
    }

    // If only the countries changed, but we already have the page set
    if (selectedPage && countriesChanged) {
      getLayouts(selectedPage, type, validatedCountries, layoutId);
    }
  }, [pageUrl, countries, layoutId, type]);

  useLayoutEffect(() => {
    if (selectedLayout) {
      // Pre-fetch all collections so that they can be searched for by name
      const collectionIds: string[] = [];
      selectedLayout.uiModules.forEach((module) => {
        const collectionId = (module as ContentListUiModuleBody).contentListId;
        if (collectionId && collectionId !== COLLECTION_ID_PARAM) {
          collectionIds.push(collectionId);
        }
      });
      if (collectionIds.length) {
        getLatestPublishedCollectionsByIds(collectionIds, true);
      }
    }
  }, [selectedLayout]);

  useLayoutEffect(() => {
    if (selectedLayoutId && allLayouts) {
      const selectedLayoutInfo = layoutInfo[selectedLayoutId];
      const layoutIndex = selectedLayoutInfo ? selectedLayoutInfo.index : -1;
      if (layoutIndex >= 0) {
        setSelectedLayout(cloneDeep(allLayouts[selectedLayoutInfo.which][layoutIndex]));
      }
    } else {
      setSelectedLayout(undefined);
    }
  }, [selectedLayoutId, allLayouts]);

  useLayoutEffect(() => {
    if (!pageUrl || !countries || !type || !layoutId) return;
    const navigatedPath = pageParamToPath(pageUrl);
    if (pages[layoutsType] && navigatedPath !== selectedPage?.urlPath) {
      const page = validateAndUpdateSelectedPage(navigatedPath);
      if (page) {
        getLayouts(page, type, validateCountries(countries), layoutId);
      }
    }
  }, [pagePages, menuPages, specialPages, promoPages]);

  useLayoutEffect(() => {
    if (selectedPage) {
      ({
        [LayoutType.PAGE]: setSelectedContentPagePath,
        [LayoutType.MENU]: setSelectedMenuPagePath,
        [LayoutType.SPECIAL]: setSelectedSpecialPagePath,
        [LayoutType.PROMOS]: setSelectedPromosPagePath
      })[layoutsType](selectedPage.urlPath);
    }
  }, [selectedPage]);

  const validateAndUpdateSelectedPage = (navigatedPath: string) => {
    if (!type || !pages[type]) return;
    if (navigatedPath === noPagePath) {
      setSelectedPage(undefined);
      return;
    }

    const requestedPage = (pages[type] as Page[]).find((page) => page.urlPath === navigatedPath);

    if (!requestedPage || !hasReadPermission(requestedPage.ownerPermissionsGroup)) {
      const accessiblePage = (pages[type] as Page[]).find((page) => hasReadPermission(page.ownerPermissionsGroup));
      const newPath = accessiblePage?.urlPath ?? noPagePath;
      updateLayoutsRoute({ pageUrl: pagePathToParam(newPath) }, true);
      return;
    }

    // We have the requested page and can access it, so switch to it
    if (selectedPage?.urlPath !== navigatedPath) {
      setSelectedPage(navigatedPath);
      return requestedPage;
    }
  };

  const validateCountries = (countries: string) => {
    return validCountryCodeFilter(countries.split('-'));
  };

  return <></>;
}
