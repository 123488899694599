import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useLocales, useNotifications, useTheme } from '../../../../../hooks';
import { Typography, FormControlLabel, Checkbox, Switch, Stack } from '@mui/material';
import CollectionPicker from '../../CollectionPicker';
import TreatmentPicker from '../../TreatmentPicker';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { SponsorSection } from '../SponsorSection';
import { allRequired, isDateValid, markAsRequired } from '../../../../../utils/formHelpers';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { ContentCollectionType, MixedListUiModuleBody, MixedListUIModuleContentTreatment } from '../../../../../API';
import { Controller, useFormContext } from 'react-hook-form';
import { FormBody, FormField } from '../../styles';
import { Box } from '@mui/system';
import { COLLECTION_ID_PARAM, isDynamicCollectionPage } from '../../../../../utils/consts/uiModules';
import { useRecoilValue } from 'recoil';
import { withSelectedLayout } from '../../../../../state/Layouts';

export const testIds = {
  formBody: 'mixed-list-form.form-body',
  assetBrowserLandscape: 'mixed-list-form.asset-browser-landscape',
  assetBrowserPortrait: 'mixed-list-form.asset-browser-portrait',
  dynamicCollectionSwitch: 'mixed-list-form.dynamic-collection-switch'
};

const MixedListForm = forwardRef<UIModuleTypeForm<MixedListUiModuleBody>>((_, ref) => {
  const { control, watch, setValue } = useFormContext<MixedListUiModuleBody>();
  const { t } = useLocales();
  const { notifyError } = useNotifications();
  const { formControlColor } = useTheme();
  const selectedLayout = useRecoilValue(withSelectedLayout);

  const [entitlements, landscapeImage, portraitImage, contentListId] = watch([
    'entitlements',
    'landscapeFillImage',
    'portraitFillImage',
    'contentListId'
  ]);

  const collectionIdParamIsSelected = contentListId === COLLECTION_ID_PARAM;
  const shouldShowCollectionPreview = selectedLayout && isDynamicCollectionPage(selectedLayout.urlPath);

  const [formErrors, setFormErrors] = useState<Record<'landscapeImage' | 'portraitImage', boolean>>({
    landscapeImage: false,
    portraitImage: false
  });

  const renderFormLabel = (label: string) => {
    return (
      <Typography component="div" color="textSecondary" variant="body2">
        {label}
      </Typography>
    );
  };

  const renderAssetBrowser = (
    type: AssetTypesEnum,
    value: string | undefined,
    hasError: boolean,
    onChange: (value: string) => void,
    testId: string
  ) => (
    <AssetBrowser
      assetId={contentListId || ''}
      assetType={type}
      value={value}
      hasError={hasError}
      onChange={({ target: { value } }) => onChange(value)}
      data-testid={testId}
    />
  );

  useEffect(() => {
    setFormErrors({ landscapeImage: false, portraitImage: false });
  }, [landscapeImage, portraitImage]);

  useImperativeHandle(ref, () => ({
    isValid(uiModule) {
      let valid = !!uiModule.contentListId;

      // NOTE: Images should error out if portrait is defined and landscape is not (or viceversa)
      const imageErrors = {
        landscapeImage: !!uiModule.portraitFillImage && !uiModule.landscapeFillImage,
        portraitImage: !!uiModule.landscapeFillImage && !uiModule.portraitFillImage
      };

      setFormErrors(imageErrors);

      const sponsorValid = allRequired([uiModule.sponsorLogo, uiModule.sponsorStartDate, uiModule.sponsorEndDate]);
      const dateValid = isDateValid(uiModule.sponsorStartDate, uiModule.sponsorEndDate);

      valid = valid && !Object.values(imageErrors).some((error) => error);

      if (!sponsorValid) {
        notifyError(t('errors.layouts.sponsor_invalid'));
        valid = false;
      }
      if (
        valid &&
        sponsorValid &&
        uiModule.sponsorLogo &&
        (!uiModule.landscapeFillImage || !uiModule.portraitFillImage)
      ) {
        setFormErrors({ landscapeImage: true, portraitImage: true });
        notifyError(t('errors.layouts.content_list_sponsor_invalid'));
        valid = false;
      }
      if (valid && !dateValid) {
        notifyError(t('errors.layouts.sponsor_invalid'));
        valid = false;
      }

      return valid;
    }
  }));

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <Controller
          control={control}
          name="useCollectionTitle"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              label={t('layouts.use_collection_name')}
              control={
                <Checkbox
                  name={t('layouts.use_collection_name')}
                  checked={value === undefined ? false : value}
                  color={formControlColor}
                  onChange={onChange}
                />
              }
            />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="isPlaylist"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              label={t('layouts.work_as_playlist')}
              control={
                <Checkbox
                  name={t('layouts.work_as_playlist')}
                  checked={value}
                  color={formControlColor}
                  onChange={onChange}
                />
              }
            />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="reorderBasedOnRecommendation"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              label={t('layouts.reorder_based_on_recommendation')}
              control={
                <Checkbox
                  name={t('layouts.reorder_based_on_recommendation')}
                  checked={value}
                  color={formControlColor}
                  onChange={onChange}
                />
              }
            />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="contentTreatment"
          render={({ field: { value, onChange } }) => (
            <TreatmentPicker
              options={MixedListUIModuleContentTreatment}
              value={value}
              onChange={(value) => onChange(value as MixedListUIModuleContentTreatment)}
            />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="contentListId"
          rules={{ required: t('general.field_is_required') }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Stack gap={2}>
              {shouldShowCollectionPreview && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={collectionIdParamIsSelected}
                      color={formControlColor}
                      onChange={(event, value) => setValue('contentListId', value ? COLLECTION_ID_PARAM : '')}
                      data-testid={testIds.dynamicCollectionSwitch}
                    />
                  }
                  label={t('layouts.use_dynamic_collection_id_param')}
                />
              )}
              {!collectionIdParamIsSelected && (
                <CollectionPicker
                  label={markAsRequired(t('collections.collection'))}
                  value={value}
                  onChange={onChange}
                  entitlements={entitlements}
                  error={!!error}
                  helperText={error?.message}
                  filters={{
                    collectionTypes: [ContentCollectionType.MIXED_LIST]
                  }}
                />
              )}
            </Stack>
          )}
        />
      </div>
      <div>
        <Box display="flex" alignItems="flex-end">
          <Controller
            control={control}
            name="landscapeFillImage"
            render={({ field: { value, onChange } }) => (
              <FormField>
                {renderFormLabel(t('layouts.landscape_fill_image'))}
                {renderAssetBrowser(
                  AssetTypes.contentList.landscape,
                  value,
                  formErrors.landscapeImage,
                  onChange,
                  testIds.assetBrowserLandscape
                )}
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="portraitFillImage"
            render={({ field: { value, onChange } }) => (
              <FormField>
                {renderFormLabel(t('layouts.portrait_fill_image'))}
                {renderAssetBrowser(
                  AssetTypes.contentList.portrait,
                  value,
                  formErrors.portraitImage,
                  onChange,
                  testIds.assetBrowserPortrait
                )}
              </FormField>
            )}
          />
        </Box>
        <SponsorSection entityId={contentListId} />
      </div>
    </FormBody>
  );
});

MixedListForm.displayName = 'ContentListForm';

export default MixedListForm;
