import React from 'react';
import { Delete } from '@mui/icons-material';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { LayoutType, PageListUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import FormControl from '../../../../shared/FormControl';
import IconButton from '../../../../shared/IconButton';
import InputController from '../../../../shared/InputController';
import LocalizedInputCollection from '../../../../shared/LocalizedInputCollection';
import TextValidator from '../../../../shared/TextValidator';
import { useData } from '../../../../../data-layer';

interface IPageListContentItemProps {
  index: number;
  onDelete: () => void;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex'
  },
  rowLeft: {
    flexGrow: 1
  },
  rowRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  formField: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '50%',
    padding: theme.spacing(0, 6, 4, 0)
  }
}));

export const testIds = {
  nameInput: 'page-list-content-item.name-input',
  pagePathSelector: 'page-list-content-item.page-path-selector',
  pagePathMenuItem: 'page-list-content-item.page-path-menu-item',
  deleteItemButton: 'page-list-content-item.delete-item-button'
};

function PageListContentItem({ index, onDelete }: IPageListContentItemProps): JSX.Element {
  const { control, getValues, reset } = useFormContext<PageListUiModuleBody>();

  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { classes } = useStyles();

  const {
    pages: {
      state: { withRecordBucket: withPagesByType }
    }
  } = useData();

  const pages = useRecoilValue(withPagesByType(LayoutType.PAGE));

  return (
    <div className={classes.container}>
      <div className={classes.rowLeft}>
        <div className={classes.formField}>
          <LocalizedInputCollection
            getValues={getValues}
            reset={reset}
            fields={[
              {
                component: (
                  <InputController
                    control={control}
                    name={`pages.${index}.name`}
                    render={({ field: { value, onChange } }) => (
                      <TextValidator
                        name="name"
                        value={value}
                        onChange={onChange}
                        label={t('general.name')}
                        color={formControlColor}
                        fullWidth
                        validators={['required']}
                        errorMessages={[t('general.field_is_required')]}
                      />
                    )}
                  />
                )
              }
            ]}
          />
        </div>
        <div className={classes.formField}>
          <FormControl fullWidth>
            <InputLabel id="page-select-label">{t('layouts.page')}</InputLabel>
            <Select
              label={t('layouts.page')}
              labelId="page-select-label"
              value={getValues(`pages.${index}.urlPath`)}
              data-testid={testIds.pagePathSelector}
              onChange={({ target: { value } }) => {
                const pageModule = getValues();
                const pages = pageModule.pages.slice();
                pages[index] = { ...pages[index], urlPath: value };
                reset({
                  ...pageModule,
                  pages
                });
              }}
              disabled={!pages}
            >
              {pages &&
                pages.map((page) => (
                  <MenuItem
                    key={page.urlPath}
                    value={page.urlPath}
                    data-page-key={page.urlPath}
                    data-testid={testIds.pagePathMenuItem}
                  >
                    {page.urlPath}
                  </MenuItem>
                ))}
              {!pages && <MenuItem value=""></MenuItem>}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.rowRight}>
        <IconButton
          onClick={onDelete}
          title={t('layouts.hero_delete_content_item')}
          size="large"
          data-testid={testIds.deleteItemButton}
        >
          <Delete />
        </IconButton>
      </div>
    </div>
  );
}

export default PageListContentItem;
