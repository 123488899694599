import React from 'react';
import { ContentListUiModuleBody, MixedListUiModuleBody, Page, PageLayoutBodyV2 } from '../API';
import { UIModuleTypeForm } from '../components/Layouts/UIModuleForm/UIModuleForm';
import { FormMetadata } from '../data-layer';
import {
  blankLiveNewsModule,
  blankContentListModule,
  blankCopyModule,
  blankMenuItemModule,
  blankMenuSectionModule,
  blankPageListModule,
  blankPromotionModule,
  blankRecommendationsModule,
  blankSportsEventModule,
  blankSportsHeroModule,
  blankTournamentListModule,
  blankTournamentSplashHeroModule,
  UiModuleBody,
  blankHeroModule,
  blankContinueWatchingModule,
  blankLivePlusModule,
  blankCollectionListModule,
  blankCollectionSplashHero,
  blankInlinePromoModule,
  blankOneBrandPromoModule,
  blankAccountSuccessModule,
  blankProfileModule,
  blankInlinePageModule,
  blankPlaybackPaywallModule,
  blankAccountCreationModule,
  blankRegWallModule,
  blankPlanSummaryModule,
  blankLinkingAccountModule,
  blankBecauseYouModule,
  blankLandingPromoModule,
  blankWatchListModule,
  blankAiSearchModule,
  blankTdsGiftCardsModule,
  blankNewPartnerLandingModule,
  setDynamicFieldsOnAccountSuccessModule,
  setDynamicFieldsOnProfileModule,
  setDynamicFieldsOnOneBrandPromoModule,
  blankMixedListModule
} from '../state/Layouts';
import HeroForm from '../components/Layouts/UIModuleForm/UIModules/HeroForm';
import ContentListForm from '../components/Layouts/UIModuleForm/UIModules/ContentListForm';
import PageListForm from '../components/Layouts/UIModuleForm/UIModules/PageListForm';
import RecommendationsForm from '../components/Layouts/UIModuleForm/UIModules/RecommendationsForm';
import ContinueWatchingForm from '../components/Layouts/UIModuleForm/UIModules/ContinueWatchingForm';
import SportsEventForm from '../components/Layouts/UIModuleForm/UIModules/SportsEventForm';
import CopyForm from '../components/Layouts/UIModuleForm/UIModules/CopyForm';
import PromotionForm from '../components/Layouts/UIModuleForm/UIModules/PromotionForm';
import SportsHeroForm from '../components/Layouts/UIModuleForm/UIModules/SportsHeroForm';
import MenuItemForm from '../components/Layouts/UIModuleForm/UIModules/MenuItemForm';
import MenuSectionForm from '../components/Layouts/UIModuleForm/UIModules/MenuSectionForm';
import ChannelsForm from '../components/Layouts/UIModuleForm/UIModules/ChannelsForm';
import TournamentListForm from '../components/Layouts/UIModuleForm/UIModules/TournamentListForm';
import TournamentSplashHeroForm from '../components/Layouts/UIModuleForm/UIModules/TournamentSplashHeroForm';
import WathcListForm from '../components/Layouts/UIModuleForm/UIModules/WatchListForm';
import LivePlusForm from '../components/Layouts/UIModuleForm/UIModules/LivePlusForm';
import CollectionListForm from '../components/Layouts/UIModuleForm/UIModules/CollectionListForm/CollectionListForm';
import CollectionSplashHero from '../components/Layouts/UIModuleForm/UIModules/CollectionSplashHero';
import InlinePromoForm from '../components/Layouts/UIModuleForm/UIModules/InlinePromoForm';
import OneBrandPromoForm from '../components/Layouts/UIModuleForm/UIModules/OneBrandPromoForm';
import AccountSuccessForm from '../components/Layouts/UIModuleForm/UIModules/AccountSuccessForm';
import ProfileForm from '../components/Layouts/UIModuleForm/UIModules/ProfileForm/ProfileForm';
import RegWallForm from '../components/Layouts/UIModuleForm/UIModules/RegWallForm/RegWallForm';
import InlinePageForm from '../components/Layouts/UIModuleForm/UIModules/InlinePageForm';
import PlaybackPaywallForm from '../components/Layouts/UIModuleForm/UIModules/PlaybackPaywallForm';
import AccountCreationForm from '../components/Layouts/UIModuleForm/UIModules/AccountCreationForm';
import PlanSummaryForm from '../components/Layouts/UIModuleForm/UIModules/PlanSummaryForm';
import LinkingAccountForm from '../components/Layouts/UIModuleForm/UIModules/LinkingAccountForm';
import BecauseYouForm from '../components/Layouts/UIModuleForm/UIModules/BecauseYouForm';
import LandingPromoForm from '../components/Layouts/UIModuleForm/UIModules/LandingPromoForm';
import AiSearchForm from '../components/Layouts/UIModuleForm/UIModules/AiSearchForm';
import TdsGiftCardsForm from '../components/Layouts/UIModuleForm/UIModules/TdsGiftCardsForm';
import SpinPremiaForm from '../components/Layouts/UIModuleForm/UIModules/SpinPremiaForm';
import NewPartnerLandingForm from '../components/Layouts/UIModuleForm/UIModules/NewPartnerLandingForm';
import MixedListForm from '../components/Layouts/UIModuleForm/UIModules/MixedListForm';

import { Control } from 'react-hook-form';
import { FieldObjectType } from '../components/shared/LocalizedInputCollection';
import { blankSpinPremiaModule } from '../state/Layouts/UIModules/withSpinPremia';
import WhoIsWatchingForm from '../components/Layouts/UIModuleForm/UIModules/WhoIsWatchingForm';
import { blankWhoIsWatchingModule } from '../state/Layouts/UIModules/withWhoIsWatching';
import AccountLoadingForm from '../components/Layouts/UIModuleForm/UIModules/AccountLoadingForm';
import { blankAccountLoadingModule } from '../state/Layouts/UIModules/withAccountLoading';

export type Unpacked<T> = T extends (infer U)[] ? U : T;
type UiModule = PageLayoutBodyV2['uiModules'];
export type UIModuleType = Unpacked<UiModule>['moduleType'];

export interface UIModuleFormMetadata extends FormMetadata<UiModuleBody> {
  index: number | number[];
}

export type NewModuleFunction<T = UiModuleBody> = (module: Partial<T>) => T;
export type DynamicModuleFunction<T = UiModuleBody> = (module: Partial<T>) => T;
type UIModuleFormRef<T> = React.ForwardRefExoticComponent<React.RefAttributes<UIModuleTypeForm<T>>>;

export type AdditionalLocalizedFieldsProps = {
  control: Control<UiModuleBody>;
  t: (key: string) => string;
};
export type AdditionalLocalizedField = (props: AdditionalLocalizedFieldsProps) => FieldObjectType;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IUiModules<T = any> = {
  Form: UIModuleFormRef<T>;
  disableTitle?: boolean | ((module: T) => boolean);
  disableEntitlements?: boolean;
  disablePlatformTargeting?: boolean;
  createNewUIModule?: NewModuleFunction<T>;
  setDynamicModuleFields?: DynamicModuleFunction<T>;
  additionalLocalizedFields?: AdditionalLocalizedField[];
};

export const UiModules: Partial<Record<UIModuleType, IUiModules>> = {
  HERO: { Form: HeroForm, createNewUIModule: blankHeroModule },
  CONTENT_LIST: {
    Form: ContentListForm,
    createNewUIModule: blankContentListModule,
    disableTitle: (m: ContentListUiModuleBody) => m.useCollectionTitle || false
  },
  PAGE_LIST: { Form: PageListForm, createNewUIModule: blankPageListModule },
  RECOMMENDATIONS: { Form: RecommendationsForm, createNewUIModule: blankRecommendationsModule },
  CONTINUE_WATCHING: { Form: ContinueWatchingForm, createNewUIModule: blankContinueWatchingModule },
  WATCH_LIST: { Form: WathcListForm, createNewUIModule: blankWatchListModule },
  BECAUSE_YOU: { Form: BecauseYouForm, disableTitle: true, createNewUIModule: blankBecauseYouModule },
  MENU_ITEM: { Form: MenuItemForm, disableTitle: true, createNewUIModule: blankMenuItemModule },
  MENU_SECTION: {
    Form: MenuSectionForm,
    disableTitle: true,
    disableEntitlements: true,
    createNewUIModule: blankMenuSectionModule
  },
  SPORTS_EVENT: { Form: SportsEventForm, createNewUIModule: blankSportsEventModule },
  LIVE_NEWS: { Form: ChannelsForm, createNewUIModule: blankLiveNewsModule },
  SPORTS_HERO: { Form: SportsHeroForm, createNewUIModule: blankSportsHeroModule },
  COPY: { Form: CopyForm, disableTitle: true, createNewUIModule: blankCopyModule },
  TOURNAMENT_LIST: { Form: TournamentListForm, createNewUIModule: blankTournamentListModule },
  TOURNAMENT_SPLASH_HERO: { Form: TournamentSplashHeroForm, createNewUIModule: blankTournamentSplashHeroModule },
  PROMOTION: { Form: PromotionForm, createNewUIModule: blankPromotionModule },
  LIVE_PLUS: { Form: LivePlusForm, createNewUIModule: blankLivePlusModule },
  COLLECTION_LIST: { Form: CollectionListForm, createNewUIModule: blankCollectionListModule },
  COLLECTION_SPLASH_HERO: { Form: CollectionSplashHero, createNewUIModule: blankCollectionSplashHero },
  INLINE_PROMO: { Form: InlinePromoForm, createNewUIModule: blankInlinePromoModule },
  ACCOUNT_SUCCESS: {
    Form: AccountSuccessForm,
    createNewUIModule: blankAccountSuccessModule,
    setDynamicModuleFields: setDynamicFieldsOnAccountSuccessModule
  },
  ONE_BRAND_PROMO: {
    Form: OneBrandPromoForm,
    createNewUIModule: blankOneBrandPromoModule,
    setDynamicModuleFields: setDynamicFieldsOnOneBrandPromoModule
  },
  PROFILE: {
    Form: ProfileForm,
    createNewUIModule: blankProfileModule,
    setDynamicModuleFields: setDynamicFieldsOnProfileModule
  },
  REGISTRATION_WALL: { Form: RegWallForm, createNewUIModule: blankRegWallModule },
  INLINE_PAGE: { Form: InlinePageForm, createNewUIModule: blankInlinePageModule },
  PLAYBACK_PAYWALL: { Form: PlaybackPaywallForm, disableTitle: true, createNewUIModule: blankPlaybackPaywallModule },
  ACCOUNT_CREATION: { Form: AccountCreationForm, disableTitle: true, createNewUIModule: blankAccountCreationModule },
  PLAN_SUMMARY: { Form: PlanSummaryForm, disableTitle: true, createNewUIModule: blankPlanSummaryModule },
  LINKING_ACCOUNT: { Form: LinkingAccountForm, disableTitle: true, createNewUIModule: blankLinkingAccountModule },
  LANDING_PROMO: { Form: LandingPromoForm, createNewUIModule: blankLandingPromoModule },
  AI_SEARCH: { Form: AiSearchForm, disableTitle: true, createNewUIModule: blankAiSearchModule },
  TDS_GIFT_CARDS: { Form: TdsGiftCardsForm, createNewUIModule: blankTdsGiftCardsModule },
  SPIN_PREMIA: { Form: SpinPremiaForm, disableTitle: true, createNewUIModule: blankSpinPremiaModule },
  NEW_PARTNER_LANDING: {
    Form: NewPartnerLandingForm,
    disableTitle: true,
    createNewUIModule: blankNewPartnerLandingModule
  },
  WHO_IS_WATCHING: { Form: WhoIsWatchingForm, disableTitle: true, createNewUIModule: blankWhoIsWatchingModule },
  ACCOUNT_LOADING: { Form: AccountLoadingForm, disableTitle: true, createNewUIModule: blankAccountLoadingModule },
  MIXED_LIST: {
    Form: MixedListForm,
    createNewUIModule: blankMixedListModule,
    disableTitle: (m: MixedListUiModuleBody) => m.useCollectionTitle || false
  }
};

export const pageToPathParam = (page: Page): string => {
  return pagePathToParam(page.urlPath);
};

export const pagePathToParam = (pagePath: string): string => {
  return encodeURIComponent(pagePath.slice(1));
};

export const pageParamToPath = (pageParam: string): string => {
  return '/' + decodeURIComponent(pageParam);
};

export const countriesToParam = (countries: string[]): string => {
  return countries.join('-');
};
