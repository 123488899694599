import { ContentServiceApi, ReverseOrderResponse, ContentPatchBody, Content } from '../../../API';
import { useLocales } from '../../../hooks';
import {
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps,
  DataManagerHelpers
} from '../../DataManager';

export interface ContentServiceHookReturnType extends DataManagerHookReturnType<ReverseOrderResponse> {
  patch: (contentId: string, body: ContentPatchBody) => Promise<Content | null>;
}

export function ContentServiceHook(params: IDataManagerHookProps<ReverseOrderResponse>): ContentServiceHookReturnType {
  const dataManagerHook = DataManagerHook<ReverseOrderResponse>(params);

  const api = params.useApiHook() as ContentServiceApi;

  const { t } = useLocales();
  const { handleApiError } = DataManagerHelpers({ name: params.name, toString });

  const patch = async (contentId: string, body: ContentPatchBody): Promise<Content | null> => {
    try {
      const content = await api.patch(contentId, body);
      return content.data.body.content;
    } catch (err) {
      console.error(err);
      handleApiError(err, t('errors.ContentService.get_genres'));
      return null;
    }
  };

  return {
    ...dataManagerHook,
    patch
  };
}
