import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Search, AddOutlined, Edit, ViewList, BurstMode, MoreVertOutlined } from '@mui/icons-material';
import { useLocales } from '../../../hooks';
import Button from '../../shared/Button';
import ShadowScroller from '../../shared/ShadowScroller';
import TextField from '../../shared/TextField';
import { TournamentForm } from '../TournamentForm';
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Tooltip,
  Typography,
  Button as MuiButton,
  Popover
} from '@mui/material';
import IconButton from '../../shared/IconButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import TeamForm from '../TeamForm/TeamForm';
import Localized from '../../shared/Localized';
import FormControl from '../../shared/FormControl';
import { useTextFilter, useTheme } from '../../../hooks';
import NoMediaItems from '../../shared/NoMediaItems';
import { IsDeletableResponse, PermissionsGroupId, TeamResponse, TournamentResponse } from '../../../API';
import { scrollIntoViewIfNeeded } from '../../../utils/scrollIntoViewIfNeeded';
import Repeat from '../../shared/Repeat';
import { NavLink, useNavigate } from 'react-router-dom';
import { SportsLogo, SPORTS_LOGO_SIZE } from '../SportsLogo';
import { EMPTY_TOURNAMENT, EMPTY_TOURNAMENT_ID, useData } from '../../../data-layer';
import { AppRoutes } from '../../../Routes';
import { TeamListItem } from './TeamListItem';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import useConfirm from '../../../hooks/General/useConfirm';
import SportsForm from '../SportsForm';
import { ObjectTreeView } from '../../shared/ObjectTreeView';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  leftColumn: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${theme.palette.divider}`
  },
  rightColumn: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  topPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    gap: theme.spacing(7)
  },
  middlePanel: {
    flexGrow: 1
  },
  bottomPanel: {
    display: 'flex',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between'
  },
  teamSearchBar: {
    display: 'flex',
    gap: theme.spacing(4),
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  searchBar: {
    marginTop: theme.spacing(4),
    maxWidth: 400
  },
  globalSearchToggle: {
    marginTop: theme.spacing(4),
    '& .MuiFormControlLabel-label': {
      fontSize: '0.8rem',
      whiteSpace: 'nowrap'
    }
  },
  sportSelectContainer: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  sportSelector: {
    minWidth: 140
  },
  skeletonText: {
    width: 300
  },
  skeletonLogo: {
    width: SPORTS_LOGO_SIZE,
    height: SPORTS_LOGO_SIZE
  },
  skeletonIcon: {
    width: '1.8rem',
    height: '1.8rem'
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  listItemLeftSide: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  noTeams: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  emptyIcon: {
    visibility: 'hidden'
  },
  treeView: {
    flexGrow: 1,
    overflowY: 'auto',
    width: 400,
    maxHeight: 300,
    padding: theme.spacing(2, 0),
    wordBreak: 'break-all'
  }
}));

export const testIds = {
  addNewTeam: 'sports-manager.add-new-team',
  addNewLeague: 'sports-manager.add-new-league',
  eventSpreadsheet: 'sports-manager.event-spreedsheet',
  leagueListItem: 'sports-manager.league-list-item',
  editLeagueButton: 'sports-manager.edit-league-button',
  leagueTextFilter: 'sports-manager.league-text-filter',
  teamTextFilter: 'sports-manager.team-text-filter',
  sportSelect: 'sports-manager.select-sport',
  sportSelectItem: 'sports-manager.select-sport-item',
  globalSearchCheckbox: 'sports-manager.global-search-checkbox',
  sportMenuButton: 'sports-manager.menu-button',
  editSportButton: 'sports-manager.edit-sport-button',
  newSportButton: 'sports-manager.new-sport-button',
  deleteSportButton: 'sports-manager.delete-sport-button',
  deleteSportConfirmDialog: 'sports-manager.delete-sport-confirm-dialog'
};

const openSpreadsheet = () => {
  const spreadsheet = process.env.REACT_APP_EVENT_SPREADSHEET || '';
  window.open(spreadsheet);
};

export default function SportsManager(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const {
    onFilterInputChange: onLeagueFilterChange,
    clearFilter: clearLeagueFilter,
    filteredResults: filterLeagues
  } = useTextFilter(['name', { field: 'tournamentId' }]);
  const {
    onFilterInputChange: onTeamFilterChange,
    clearFilter: clearTeamFilter,
    filteredResults: filterTeams
  } = useTextFilter(['name', { field: 'teamId' }]);
  const leagueRefs = useRef<Record<string, HTMLElement | null>>({} as Record<string, HTMLElement | null>);
  const {
    sports: {
      state: { withAllRecords: withAllSports, withSelectedId: withSelectedSportTypeId },
      hook: {
        remove: deleteSport,
        edit: editSport,
        getAll: getAllSports,
        new: newSport,
        setSelected: setSelectedSportId,
        validateDeletion
      }
    },
    tournaments: {
      state: {
        withTournamentsBySport,
        withSelectedId: withSelectedTournamentId,
        withSelected: withSelectedTournament,
        withIsSaving: withIsSavingTournament
      },
      hook: {
        getAll: getAllTournaments,
        setSelected: setSelectedTournamentId,
        edit: editTournament,
        new: newTournament
      }
    },
    teams: {
      state: { withIsGlobalTeamSearch, withIsSaving: withIsSavingTeam, withRecordBucket: withTeamsByTournament },
      hook: { searchTeams, getBucket: getTeamsByTournament, new: newTeam }
    }
  } = useData();
  // Global state
  const [sportMenuAnchorEl, setSportMenuAnchorEl] = useState<null | HTMLElement>(null);
  const sports = useRecoilValue(withAllSports);
  const selectedSportId = useRecoilValue(withSelectedSportTypeId);
  const selectedTournamentId = useRecoilValue(withSelectedTournamentId);
  const selectedTournament = useRecoilValue(withSelectedTournament);
  const isSavingTeam = useRecoilValue(withIsSavingTeam);
  const isSavingTournament = useRecoilValue(withIsSavingTournament);
  const selectedSportTournaments = useRecoilValue(withTournamentsBySport(selectedSportId));
  const [isGlobalTeamSearch, setIsGlobalTeamSearch] = useRecoilState(withIsGlobalTeamSearch);
  const tournamentTeams = useRecoilValue(withTeamsByTournament(selectedTournamentId));
  // Local state
  const [isSearchingTeams, setIsSearchingTeams] = useState<boolean>(false);
  const [teamSearchTerm, setTeamSearchTerm] = useState<string>('');
  const [globalSearchTeams, setGlobalSearchTeams] = useState<TeamResponse[]>([]);
  const { canSave, canDelete, canRead } = usePermissionsGuard({
    homepageOption: HomepageOptions.SPORTS
  });
  const { hasUpsertPermission, hasReadPermission, hasDeletePermission } = usePermissions();
  const hasSportsDeletePermission = hasDeletePermission(PermissionsGroupId.SPORTS) || canDelete;
  const hasSportsUpsertPermission = hasUpsertPermission(PermissionsGroupId.SPORTS) && canSave;
  const hasSportsReadPermission = hasReadPermission(PermissionsGroupId.SPORTS) || canRead;

  useEffect(() => {
    if (!hasSportsReadPermission) {
      navigate(AppRoutes.welcome);
    }
  }, [hasSportsReadPermission]);

  useEffect(() => {
    getAllSports();
    getAllTournaments();
  }, []);

  useEffect(() => {
    if (!selectedSportId && sports && sports.length) {
      // Initialize the selected sport if it is not set
      setSelectedSportId(sports[0].id);
    }
  }, [sports]);

  useEffect(() => {
    if (!selectedSportTournaments) return;
    leagueRefs.current = { ...leagueRefs.current };
    if (
      !selectedTournamentId ||
      (selectedSportTournaments && !selectedSportTournaments.find((league) => selectedTournamentId === league.id))
    ) {
      setSelectedTournamentId(selectedSportTournaments[0]?.id || EMPTY_TOURNAMENT_ID);
    } else {
      setSelectedTournamentId(selectedTournamentId);
    }
  }, [selectedSportTournaments]);

  useEffect(() => {
    onSelectedTournamentChange();
  }, [selectedTournament]);

  useEffect(() => {
    if (isGlobalTeamSearch) {
      if (teamSearchTerm) {
        searchTeamsGlobally();
        return;
      }
      onTeamFilterClear();
    }
    setIsSearchingTeams(false);
  }, [teamSearchTerm]);

  const onSportChange = (event: SelectChangeEvent<string>): void => {
    if (!sports) return;
    setSelectedSportId(event.target.value);
  };

  const onTeamFilterLocalChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    onTeamFilterChange(evt);
    setIsSearchingTeams(!!evt.target.value);
    setTeamSearchTerm(evt.target.value);
  };

  const onSelectedTournamentChange = async () => {
    if (!selectedTournament) return;
    if (leagueRefs.current[selectedTournamentId]) {
      scrollIntoViewIfNeeded(leagueRefs.current[selectedTournamentId] as HTMLElement);
    }
    getTeamsByTournament?.(selectedTournament.id);
  };

  const searchTeamsGlobally = async () => {
    setGlobalSearchTeams((await searchTeams(teamSearchTerm)) || []);
    setIsSearchingTeams(false);
  };

  const onTeamFilterClear = () => {
    clearTeamFilter();
    setTeamSearchTerm('');
    setGlobalSearchTeams([]);
    setIsSearchingTeams(false);
  };

  const onGlobalSearchCheckboxChange = (value: boolean) => {
    setIsGlobalTeamSearch(value);
    if (value && teamSearchTerm) {
      setIsSearchingTeams(true);
      searchTeamsGlobally();
    }
  };

  const onNewTournamentClick = () => {
    newTournament(selectedSportId);
  };

  const onNewTeamClick = () => {
    newTeam(selectedSportId, selectedTournamentId !== EMPTY_TOURNAMENT_ID ? selectedTournamentId : undefined);
  };

  const filteredTournaments: TournamentResponse[] =
    selectedSportTournaments && sports && selectedSportId && selectedSportTournaments
      ? filterLeagues([EMPTY_TOURNAMENT, ...selectedSportTournaments])
      : [EMPTY_TOURNAMENT];
  const filteredTeams = tournamentTeams ? filterTeams(tournamentTeams) : [];
  const teamsToDisplay = isGlobalTeamSearch && teamSearchTerm ? globalSearchTeams : filteredTeams;

  const SkeletonRow = () => (
    <div className={classes.listItem}>
      <div className={classes.listItemLeftSide}>
        <Skeleton variant="circular" className={classes.skeletonLogo} animation="wave" />
        <Skeleton className={classes.skeletonText} animation="wave" />
      </div>
      <IconButton className={classes.emptyIcon} edge="end" size="large" disabled>
        <Edit fontSize="small" />
      </IconButton>
      <Skeleton variant="circular" className={classes.skeletonIcon} animation="wave" />
    </div>
  );

  const openSportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSportMenuAnchorEl(event.currentTarget);
  };

  const closeSportMenu = () => {
    setSportMenuAnchorEl(null);
  };

  const handleNewSport = () => {
    closeSportMenu();
    newSport();
  };

  const handleEditSport = () => {
    closeSportMenu();
    if (selectedSportId) {
      void editSport(selectedSportId);
    }
  };

  const handleSportDeletionMessages = (deletionResponse: IsDeletableResponse) => {
    if (deletionResponse.code) {
      return (
        <>
          <Typography>{t('sports.sport_has_associations')}</Typography>
          {deletionResponse.teams && (
            <ObjectTreeView
              defaultExpanded={[]}
              object={deletionResponse.teams}
              title={t('sports.teams')}
              className={classes.treeView}
            />
          )}
          {deletionResponse.tournaments && (
            <ObjectTreeView
              defaultExpanded={[]}
              object={deletionResponse.tournaments}
              title={t('sports.tournaments')}
              className={classes.treeView}
            />
          )}
          {deletionResponse.pageLayouts && (
            <ObjectTreeView
              defaultExpanded={[]}
              object={deletionResponse.pageLayouts}
              title={t('sports.layouts')}
              className={classes.treeView}
            />
          )}
        </>
      );
    }
    return t('sports.delete_confirm_sport');
  };

  const handleDeleteSport = async () => {
    closeSportMenu();
    if (!selectedSportId || !sports?.length) return;
    const deletionCode = await validateDeletion(selectedSportId);
    let result = false;
    if (deletionCode) {
      result = await confirm({
        confirmText: t('general.confirm_delete'),
        confirmColor: 'error',
        cancelText: deletionCode.code ? t('general.ok') : t('general.cancel'),
        body: handleSportDeletionMessages(deletionCode),
        'data-testid': testIds.deleteSportConfirmDialog
      });
    } else {
      result = await confirm({
        confirmText: t('general.confirm_delete'),
        confirmColor: 'error',
        cancelText: t('general.cancel'),
        body: t('sports.delete_confirm_sport'),
        'data-testid': testIds.deleteSportConfirmDialog
      });
    }
    if (!result) return;

    await deleteSport(selectedSportId);
    await setSelectedSportId(undefined);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.leftColumn}>
          <div className={classes.topPanel}>
            <Typography variant="h5">{t('leagues.title')}</Typography>
            {sports && (
              <>
                <div className={classes.sportSelectContainer}>
                  <FormControl>
                    <div>
                      <InputLabel id="sports-select-label">{t('sports.sport')}</InputLabel>

                      <Select
                        label={t('sports.sport')}
                        labelId="sports-select-label"
                        className={classes.sportSelector}
                        data-testid={testIds.sportSelect}
                        value={selectedSportId}
                        onChange={onSportChange}
                      >
                        {sports.map((sport, i) => (
                          <MenuItem key={i} value={sport.id} data-testid={testIds.sportSelectItem}>
                            <Localized prop={sport.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </FormControl>
                  <IconButton
                    data-testid={testIds.sportMenuButton}
                    disabled={!sports}
                    onClick={openSportMenu}
                    title={t('tooltips.options')}
                    // size="large"
                  >
                    <MoreVertOutlined />
                  </IconButton>
                  <Popover
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    anchorEl={sportMenuAnchorEl}
                    keepMounted
                    open={Boolean(sportMenuAnchorEl)}
                    onClose={closeSportMenu}
                  >
                    <MenuItem
                      onClick={handleEditSport}
                      disabled={!hasSportsUpsertPermission}
                      data-testid={testIds.editSportButton}
                    >
                      {t('edit_sport')}
                    </MenuItem>
                    <MenuItem
                      onClick={handleNewSport}
                      disabled={!hasSportsUpsertPermission}
                      data-testid={testIds.newSportButton}
                    >
                      {t('new_sport')}
                    </MenuItem>
                    <MenuItem
                      onClick={handleDeleteSport}
                      disabled={!hasSportsDeletePermission}
                      data-testid={testIds.deleteSportButton}
                    >
                      {t('delete_sport')}
                    </MenuItem>
                  </Popover>
                </div>
              </>
            )}
            <TextField
              onChange={onLeagueFilterChange}
              className={classes.searchBar}
              label={t('sports.filter_leagues')}
              clearable={true}
              onClear={() => clearLeagueFilter()}
              fullWidth
              data-testid={testIds.leagueTextFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className={classes.middlePanel}>
            <ShadowScroller saving={isSavingTournament} loading={!selectedSportTournaments}>
              {!selectedSportTournaments && (
                <Repeat n={15}>
                  <SkeletonRow />
                </Repeat>
              )}
              {filteredTournaments?.map((league) => (
                <ListItem
                  key={league.id}
                  ref={(el) => (leagueRefs.current[league.id] = el)}
                  button
                  selected={selectedTournamentId === league.id}
                  className={classes.listItem}
                  onClick={() => setSelectedTournamentId(league.id)}
                  data-testid={testIds.leagueListItem}
                  data-league-id={league.id}
                >
                  <div className={classes.listItemLeftSide}>
                    <SportsLogo logoImagePath={league.logoImage} />
                    {league.tournamentId && (
                      <Tooltip
                        arrow
                        title={`Opta ID: ${league.tournamentId}`}
                        placement="top"
                        PopperProps={{ disablePortal: true }}
                      >
                        <div>
                          <Localized prop={league.name} />
                        </div>
                      </Tooltip>
                    )}
                    {!league.tournamentId && <Localized prop={league.name} />}
                  </div>
                  <IconButton
                    className={league.tournamentId === EMPTY_TOURNAMENT_ID ? classes.emptyIcon : ''}
                    edge="end"
                    size="large"
                    onClick={() => editTournament(league.id)}
                    data-testid={testIds.editLeagueButton}
                    disabled={!league.tournamentId || league.tournamentId === EMPTY_TOURNAMENT_ID}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </ListItem>
              ))}
            </ShadowScroller>
          </div>
          <div className={classes.bottomPanel}>
            <div>
              <Button
                onClick={onNewTournamentClick}
                variant="contained"
                color="primary"
                disabled={!selectedSportTournaments || !hasSportsUpsertPermission}
                endIcon={<AddOutlined />}
                data-testid={testIds.addNewLeague}
              >
                {t('sports.new_league')}
              </Button>
            </div>
            <div>
              <MuiButton
                component={NavLink}
                color="grey"
                variant="contained"
                to={AppRoutes.configSports}
                endIcon={<BurstMode />}
                disableElevation
              >
                {t('topbar.fallback_images')}
              </MuiButton>
            </div>
          </div>
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.topPanel}>
            <Typography variant="h5">{t('sports.teams')}</Typography>
            <div className={classes.teamSearchBar}>
              <TextField
                debounced={isGlobalTeamSearch}
                value={teamSearchTerm}
                onChange={onTeamFilterLocalChange}
                className={classes.searchBar}
                label={t('sports.filter_teams')}
                clearable={true}
                onClear={onTeamFilterClear}
                fullWidth
                data-testid={testIds.teamTextFilter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
              <FormControlLabel
                className={classes.globalSearchToggle}
                label={t('general.search_globally')}
                control={
                  <Checkbox
                    checked={isGlobalTeamSearch}
                    color={formControlColor}
                    onChange={({ target: { checked } }) => onGlobalSearchCheckboxChange(checked)}
                    data-testid={testIds.globalSearchCheckbox}
                  />
                }
              />
            </div>
          </div>
          <div className={classes.middlePanel}>
            <ShadowScroller saving={isSavingTeam} loading={isSearchingTeams || !tournamentTeams}>
              {((isGlobalTeamSearch && isSearchingTeams) || !tournamentTeams) && (
                <Repeat n={15}>
                  <SkeletonRow />
                </Repeat>
              )}
              {tournamentTeams && !isSearchingTeams && teamsToDisplay.length === 0 && (
                <div className={classes.noTeams}>
                  {teamSearchTerm && (
                    <NoMediaItems
                      icon={<Search />}
                      header={t('general.no_search_results.header')}
                      message={t('general.no_search_results.message')}
                    />
                  )}
                  {!teamSearchTerm && (
                    <NoMediaItems
                      header={t('general.no_teams_in_league.header')}
                      message={t('general.no_teams_in_league.message')}
                    />
                  )}
                </div>
              )}
              {teamsToDisplay.length > 0 &&
                teamsToDisplay.map((team) => <TeamListItem key={team.id} teamId={team.id} />)}
            </ShadowScroller>
          </div>
          <div className={classes.bottomPanel}>
            <div>
              <Button
                onClick={onNewTeamClick}
                variant="contained"
                color="primary"
                endIcon={<AddOutlined />}
                disabled={!selectedSportTournaments || !hasSportsUpsertPermission}
                data-testid={testIds.addNewTeam}
              >
                {t('sports.new_team')}
              </Button>
            </div>
            <div>
              <Button
                onClick={openSpreadsheet}
                variant="contained"
                color="grey"
                endIcon={<ViewList />}
                data-testid={testIds.eventSpreadsheet}
              >
                {t('sports.event_spreadsheet')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <TournamentForm />
      <TeamForm />
      <SportsForm />
    </>
  );
}
