import React, { useEffect, useRef, useState } from 'react';
import { useData } from '../../../../../data-layer';
import { ArrowDropDown, Delete, Edit, SavedSearch } from '@mui/icons-material';
import { Button, Chip, IconButton, MenuItem, Popover, Skeleton, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecoilValue } from 'recoil';
import { SavedSearchResponse } from '../../../../../API';
import SavedSearchForm from './SavedSearchForm';
import { useConfirm, useLocales } from '../../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { ContentSearchWithFilters } from '../ContentSearchFilters';

const useStyles = makeStyles()((theme) => ({
  menuItem: {
    padding: theme.spacing(3),
    display: 'flex',
    gap: theme.spacing(3)
  },
  menuItemLabel: {
    flexGrow: 1
  },
  buttonContainer: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  deleteConfirmationMessage: {
    marginTop: '20px'
  },
  deleteSavedSearchWarning: {
    fontSize: '14px',
    marginTop: '20px'
  },
  deleteWarningLabel: {
    marginRight: theme.spacing(1)
  }
}));

export const testIds = {
  root: 'saved-searches.root',
  open: 'saved-searches.open',
  confirmDialog: 'saved-searches.confirm-dialog',
  savedSearch: 'saved-searches.saved-search',
  edit: 'saved-searches.edit',
  delete: 'saved-searches.delete',
  loader: 'saved-searches.loader',
  saveButton: 'saved-searches.save-button'
};

export interface ISavedSearchesProp {
  onLoad: (savedSearch: SavedSearchResponse) => void;
}

export function SavedSearches({ onLoad }: ISavedSearchesProp): JSX.Element {
  const anchorRef = useRef(null);
  const { classes } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const { getValues } = useFormContext<ContentSearchWithFilters>();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { t } = useLocales();
  const {
    savedSearches: { state: savedSearchState, hook: useSavedSearches }
  } = useData();

  useEffect(() => {
    void getSavedSearches();
  }, []);

  const getSavedSearches = async () => {
    await useSavedSearches.getAll();
  };

  const { confirm } = useConfirm();
  const savedSearches: SavedSearchResponse[] | undefined = useRecoilValue(savedSearchState.withAllRecords);

  const closeSavedSearchForm = () => {
    setOpen(true);
  };
  const loadSearch = (search: SavedSearchResponse) => {
    return (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onLoad(search);
      setOpen(false);
    };
  };

  const createSearch = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
    useSavedSearches.new(getValues());
  };

  const editSearch = (search: SavedSearchResponse) => {
    return (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(false);
      useSavedSearches.edit(search.id);
    };
  };

  const deleteSearch = (searchToDelete: SavedSearchResponse) => {
    return async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const confirmDeleteModal = (
        <div>
          <div className={classes.deleteConfirmationMessage}>{t('confirm.delete_saved_search')}</div>
          <div className={classes.deleteSavedSearchWarning}>
            <Chip color="warning" label={t('filters.warning')} className={classes.deleteWarningLabel} size="small" />
            {t('confirm.delete_saved_search_name', { name: searchToDelete.name })}
          </div>
        </div>
      );

      const result = await confirm({
        confirmColor: 'error',
        confirmText: t('general.confirm_delete'),
        body: confirmDeleteModal,
        'data-testid': testIds.confirmDialog
      });

      if (result && searchToDelete) {
        await useSavedSearches.remove(searchToDelete.id);
      }
    };
  };

  return (
    <div data-testid={testIds.root}>
      <Tooltip title={t('collections.show_saved_searches')} arrow placement="left">
        <Button
          ref={anchorRef}
          endIcon={<ArrowDropDown />}
          color="grey"
          onClick={handleOpen}
          variant="contained"
          size="small"
          data-testid={testIds.open}
          disableElevation
        >
          <SavedSearch />
        </Button>
      </Tooltip>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <div className={classes.buttonContainer}>
          <Button
            fullWidth
            onClick={createSearch}
            data-testid={testIds.saveButton}
            variant="contained"
            disableElevation
          >
            {t('collections.save_current_search')}
          </Button>
        </div>
        {!savedSearches && <Skeleton animation="wave" className={classes.menuItem} data-testid={testIds.loader} />}
        {savedSearches &&
          savedSearches.map((savedSearch) => (
            <MenuItem
              key={savedSearch.id}
              className={classes.menuItem}
              onClick={loadSearch(savedSearch)}
              data-testid={testIds.savedSearch}
            >
              <div className={classes.menuItemLabel}>{savedSearch.name}</div>
              <IconButton size="small" onClick={editSearch(savedSearch)} data-testid={testIds.edit}>
                <Edit fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={deleteSearch(savedSearch)} data-testid={testIds.delete}>
                <Delete fontSize="small" />
              </IconButton>
            </MenuItem>
          ))}
      </Popover>
      <SavedSearchForm onClose={closeSavedSearchForm} />
    </div>
  );
}
