import {
  MixedListUiModuleBody,
  MixedListUiModuleBodyModuleTypeEnum,
  MixedListUIModuleContentTreatment,
  DocumentLocale
} from '../../../API';
import generateId from '../../../utils/generateId';
import { NewModuleFunction } from '../../../utils/layouts';

export const blankMixedListModule: NewModuleFunction<MixedListUiModuleBody> = (module) => ({
  title: {
    [DocumentLocale.ES]: ''
  },
  isPlaylist: false,
  moduleType: MixedListUiModuleBodyModuleTypeEnum.MIXED_LIST,
  contentTreatment: MixedListUIModuleContentTreatment.PORTRAIT,
  contentListId: '',
  moduleId: generateId(),
  useCollectionTitle: false,
  ...module
});
