import React from 'react';
import { Box, InputLabel, MenuItem, Typography } from '@mui/material';
import { useLocales } from '../../../../hooks';
import { makeStyles } from 'tss-react/mui';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { markAsRequired } from '../../../../utils/formHelpers';
import FormControl from '../../FormControl';
import Select from '../../Select';
import FoldableSection from '../../FoldableSection';
import { getReverseOrderOptions } from '../../../../utils/contentService';
import Button from '../../Button';
import { ReverseOrder } from '../../../../API';
import { useData } from '../../../../data-layer';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';
import { useRecoilValue } from 'recoil';

interface ReverseChronologicalProps {
  contentId?: string;
}

export interface ReverseChronological {
  reverseChronologicalOrder: string;
}

export const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  formRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(4)
  },
  selector: {
    minWidth: 260
  },
  saveAndPublishBtn: {
    marginTop: theme.spacing(4)
  }
}));

export const testIds = {
  formBody: 'reverse-chron.form-body',
  reverseChronSelector: 'reverse-chron.selector',
  reverseChronSelectorItem: 'reverse-chron.selector-item'
};

export function ReverseChronological({ contentId }: ReverseChronologicalProps): JSX.Element {
  const { classes } = useStyles();
  const {
    media: {
      state: { withRecordById },
      hook: { closeForm }
    },
    contentServices: {
      hook: { patch }
    }
  } = useData();
  const { t } = useLocales();
  const { canPublish: canPublishReverseChron } = usePermissionsGuard({
    homepageOption: HomepageOptions.METADATA
  });
  const content = useRecoilValue(withRecordById(contentId));

  const methods = useForm<ReverseChronological>({
    defaultValues: {
      reverseChronologicalOrder: content?.reverseOrder || ReverseOrder.NONE
    }
  });
  const { control } = methods;
  const options = getReverseOrderOptions();

  const handleSubmit = async (data: ReverseChronological) => {
    if (contentId) {
      const content = await patch(contentId, {
        reverseOrder: data.reverseChronologicalOrder as ReverseOrder
      });

      if (content) {
        closeForm();
      }
    }
  };

  const handleSaveAndPublish = async () => {
    methods.handleSubmit(handleSubmit)();
  };

  return (
    <Box className={classes.root} data-testid={testIds.formBody}>
      <FormProvider {...methods} handleSubmit={methods.handleSubmit}>
        <FoldableSection
          summary={<Typography variant="h6">{t('content.drawer.reverse_chronological.foldable_title')}</Typography>}
          expanded
          disableGutters
        >
          <Box className={classes.form}>
            <div className={classes.formRow}>
              <Controller
                control={control}
                rules={{ required: t('general.field_is_required') }}
                name="reverseChronologicalOrder"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <FormControl>
                    <InputLabel id="typeSelector">
                      {markAsRequired(t('content.drawer.reverse_chronological.order'), true)}
                    </InputLabel>
                    <Select
                      label={markAsRequired(t('content.drawer.reverse_chronological.order'), true)}
                      className={classes.selector}
                      required
                      labelId="typeSelector"
                      value={value}
                      onChange={({ target: { value } }) => onChange(value as string)}
                      data-testid={testIds.reverseChronSelector}
                      error={!!error}
                    >
                      {options.map(({ value, label }) => (
                        <MenuItem
                          key={value}
                          value={value}
                          data-testid={testIds.reverseChronSelectorItem}
                          data-treatment-id={value}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </Box>
        </FoldableSection>
        <Box className={classes.saveAndPublishBtn}>
          <Button onClick={handleSaveAndPublish} disabled={!canPublishReverseChron}>
            {t('general.update')}
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}
