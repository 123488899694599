import { QuickScheduling } from '../API';
import { toMinutes } from './dateHelpers';

// Layouts
export const DUMMY_LAYOUT_ID = '1';
export const DEFAULT_LAYOUTS_PAGE_URL = '/ondemand';
export const DEFAULT_LAYOUTS_MENU_URL = '/nav/web/settings';
export const DEFAULT_LAYOUTS_SPECIAL_URL = '/ayuda';
export const DEFAULT_LAYOUTS_PROMO_URL = '/promo/7-days-free';
export const DEFAULT_LAYOUTS_COUNTRIES = ['US', 'MX'];

// Collections
export const DEFAULT_PAGINATION_LIMIT = 50;

// Quick Time Schedules
export const QUICK_TIME_SCHEDULES = Object.values(QuickScheduling)
  .filter((value) => !isNaN(Number(value)))
  .map((seconds) => {
    const time = toMinutes(seconds as unknown as number);
    const key = time >= 1 ? 'minutes' : 'seconds';
    return { [key]: time >= 1 ? time : seconds };
  });
