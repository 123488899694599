import { ReverseOrderResponse, OperationIds } from '../../../API';

import { CreateDataManager, DataManagerReturnType, DataManagerStateFullReturnType } from '../../DataManager';
import { useContentServiceAPI } from '../../../hooks/API/ContentService/useContentServiceAPI';
import { ContentServiceHook, ContentServiceHookReturnType } from './ContentServiceHook';

export type ContentServicesDataManagerType = {
  state: DataManagerStateFullReturnType<ReverseOrderResponse>;
  hook: ContentServiceHookReturnType;
};

export interface ContentServicesDataManagerReturnType extends DataManagerReturnType<ReverseOrderResponse> {
  state: ContentServicesDataManagerType['state'];
  hook: () => ContentServicesDataManagerType['hook'];
}

export const ContentServicesDataManager = CreateDataManager<
  ReverseOrderResponse,
  ReverseOrderResponse,
  ContentServicesDataManagerReturnType
>({
  name: 'ContentServices',
  idField: 'hash',
  useApiHook: useContentServiceAPI,
  isVersionedEntity: true,
  toString: () => 'ContentServicesDataManager',
  operationIdGetAll: OperationIds.GET_ALL,
  hookInitializer: ContentServiceHook
});
