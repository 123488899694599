import React, { useEffect, useRef } from 'react';
import { Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useRecoilValue } from 'recoil';
import { PermissionsGroupId, TeamBody, TeamResponse, TournamentResponse } from '../../../API';
import { useData } from '../../../data-layer';
import { useConfirm, useLocales, useTheme } from '../../../hooks';
import { AssetTypes } from '../../../utils/assetTypes';
import { CustomValidators } from '../../../utils/customValidators';
import { markAsRequired } from '../../../utils/formHelpers';
import AssetBrowser from '../../Assets/AssetBrowser';
import { Button } from '../../shared/Button/Button';
import Drawer from '../../shared/Drawer';
import InputController from '../../shared/InputController';
import LocalizedInputCollection from '../../shared/LocalizedInputCollection';
import { TournamentsMultiSelect } from '../TournamentsMultiSelect';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import TextValidator from '../../shared/TextValidator';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  },
  formField: {
    maxWidth: 500,
    marginBottom: theme.spacing(4)
  },
  textInput: {
    minWidth: 400,
    marginBottom: theme.spacing(4)
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  }
}));

export const testIds = {
  cancelButton: 'team-form.cancel-button',
  saveButton: 'team-form.save-button',
  deleteButton: 'team-form.delete-button',
  confirmDialog: 'team-form.confirm-delete-dialog',
  sportTypeSelect: 'team-form.sport-type-select',
  sportTypeSelectItem: 'team-form.sport-type-select-item',
  teamName: 'team-form.team-name',
  teamId: 'team-form.team-id',
  parentId: 'team-form.team-parent-id',
  teamLogo: 'team-form.team-logo',
  alternateLogo: 'team-form.alternate-logo',
  formBody: 'team-form.form-body'
};

enum TeamValidationCodes {
  TEAM_HAS_TOURNAMENTS = 'TEAM_HAS_TOURNAMENTS'
}

function TeamForm(): JSX.Element {
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { hasPermissions } = usePermissions();
  const { UPSERT: hasUpsertPermission, DELETE: hasDeletePermission } = hasPermissions(PermissionsGroupId.SPORTS);

  const {
    sports: {
      state: { withSelectedId: withSelectedSportId }
    },
    tournaments: {
      state: { withTournamentsBySport }
    },
    teams: {
      state: { withFormMetadata, withIsSaving, withIsDeleting, withIsValidatingDeletion },
      hook: { create: createTeam, update: updateTeam, closeForm, validateDeletion, remove: deleteTeam }
    }
  } = useData();
  const formMetadata = useRecoilValue(withFormMetadata);
  const team: TeamResponse = formMetadata.record as TeamResponse;
  const isSavingTeam = useRecoilValue(withIsSaving);
  const isDeleting = useRecoilValue(withIsDeleting);
  const isValidatingDeletion = useRecoilValue(withIsValidatingDeletion);

  const selectedSportId = useRecoilValue(withSelectedSportId);
  // If the team has a sport set, use that, as we may have selected the team from the search results
  const selectedSportTournaments = useRecoilValue(withTournamentsBySport(team?.sport || selectedSportId));

  const { handleSubmit, control, reset, getValues } = useForm<TeamBody | TeamResponse>();
  const { confirm } = useConfirm();

  useEffect(() => {
    reset(team);
  }, [team]);

  const saveTeam = async (teamToSave: TeamBody | TeamResponse) => {
    let savedTeam: TeamResponse | undefined;
    if (formMetadata.isNew) {
      savedTeam = await createTeam(teamToSave);
    } else {
      savedTeam = await updateTeam(team.id, teamToSave as TeamResponse);
    }
    if (savedTeam) closeForm();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const leaguePickerOnChange = (selectedLeagues: TournamentResponse[], onChange: (evt: any) => void) => {
    const selectedLeagueIds = selectedLeagues && selectedLeagues.map((s) => s.id);
    onChange({ target: { value: selectedLeagueIds } });
  };

  const leaguePickerValueTransform = (value: string[] = []) => {
    const result = selectedSportTournaments?.filter((o1) => value?.some((o2) => o1.id === o2));
    return result as TournamentResponse[];
  };

  const validateTeamDeletion = async () => {
    const deletionCode = await validateDeletion(team.id);
    if (deletionCode) {
      const result = await confirm({
        confirmText: t('general.confirm_delete'),
        confirmColor: 'error',
        cancelText: deletionCode.code ? t('general.ok') : t('general.cancel'),
        body: handleTeamDeletionMessages(deletionCode.code),
        'data-testid': testIds.confirmDialog
      });

      if (result) {
        const deleted = await deleteTeam(team.id);
        if (deleted) closeForm();
      }
    }
  };

  const handleTeamDeletionMessages = (code: string | undefined) => {
    if (code) {
      return TeamValidationCodes.TEAM_HAS_TOURNAMENTS === code
        ? t('errors.sports.team.team_has_tournaments')
        : t('errors.sports.team.team_has_matches');
    }
    return t('sports.delete_confirm');
  };

  const onClose = () => {
    if (!isSavingTeam && !isDeleting && !isValidatingDeletion) {
      closeForm();
    }
  };

  const onSubmit: SubmitHandler<TeamBody | TeamResponse> = (team) => {
    saveTeam(team);
  };

  return (
    <>
      <Drawer
        open={formMetadata.isShowingForm}
        formRef={formRef}
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
        headerLeft={<Typography variant="h6">{t(`sports.${formMetadata.isNew ? 'new' : 'edit'}_team`)}</Typography>}
        headerRight={
          !formMetadata.isNew && (
            <Button
              color="error"
              endIcon={<Delete />}
              loading={isDeleting || isValidatingDeletion}
              onClick={validateTeamDeletion}
              data-testid={testIds.deleteButton}
              disabled={!hasDeletePermission}
            >
              {t('general.delete')}
            </Button>
          )
        }
        footerLeft={
          <>
            <Button
              type="submit"
              loading={isSavingTeam}
              disabled={isDeleting || isValidatingDeletion || !hasUpsertPermission}
              className={classes.footerButton}
              data-testid={testIds.saveButton}
            >
              {t('general.save')}
            </Button>
          </>
        }
        footerRight={
          <Button
            color="grey"
            className={classes.footerButton}
            disabled={isDeleting || isValidatingDeletion}
            onClick={onClose}
            data-testid={testIds.cancelButton}
          >
            {t('general.cancel')}
          </Button>
        }
      >
        {team && (
          <div className={classes.formBody} data-testid={testIds.formBody}>
            <LocalizedInputCollection
              containerClassName={classes.formField}
              reset={reset}
              getValues={getValues}
              fields={[
                {
                  component: (
                    <InputController
                      name="name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextValidator
                          className={classes.textInput}
                          fullWidth
                          name="name"
                          color={formControlColor}
                          value={value}
                          onChange={onChange}
                          label={markAsRequired(t('general.name'))}
                          validators={[CustomValidators.requiredIfDefined]}
                          errorMessages={[t('general.field_is_required')]}
                        />
                      )}
                    />
                  )
                }
              ]}
            />
            <div className={classes.formField}>
              <Controller
                name="teamId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextValidator
                    fullWidth
                    color={formControlColor}
                    name="teamId"
                    label={markAsRequired(t('sports.team_id'))}
                    value={value}
                    validators={['required']}
                    errorMessages={[t('general.field_is_required')]}
                    onChange={onChange}
                    data-testid={testIds.teamId}
                  />
                )}
              />
            </div>
            {selectedSportTournaments && (
              <div className={classes.formField}>
                <Controller
                  name="tournaments"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TournamentsMultiSelect
                      required={false}
                      Options={selectedSportTournaments}
                      label={t('sports.leagues')}
                      value={leaguePickerValueTransform(value)}
                      onChange={(values: TournamentResponse[]) => leaguePickerOnChange(values, onChange)}
                    />
                  )}
                />
              </div>
            )}
            <div className={classes.formField}>
              <Typography variant="body2" color="textSecondary">
                {t('leagues.league_image_logo')}
              </Typography>
              <Controller
                name="logo"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AssetBrowser
                    value={value}
                    assetId={team.id || AssetTypes.sports.teamLogo}
                    assetType={AssetTypes.sports.teamLogo}
                    onChange={onChange}
                    data-testid={testIds.teamLogo}
                  />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Typography variant="body2" color="textSecondary">
                {t('sports.alternate_logo')}
              </Typography>
              <Controller
                name="alternativeLogo"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AssetBrowser
                    value={value}
                    assetId={team.id || AssetTypes.sports.teamLogo}
                    assetType={AssetTypes.sports.teamLogo}
                    onChange={onChange}
                    data-testid={testIds.alternateLogo}
                  />
                )}
              />
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
}

export default TeamForm;
