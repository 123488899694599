import { atom, selector } from 'recoil';
import {
  ContentCollectionResponse,
  ContentListUiModuleBody,
  EntitlementType,
  HeroUiModuleBody,
  LayoutType,
  PageLayoutBodyV2,
  PageLayoutResponseV2,
  LokaliseTranslation,
  PaginationMeta,
  TargetPlatform,
  MenuItemUiModuleBody,
  DocumentLocale
} from '../../API';
import { DEFAULT_LAYOUTS_COUNTRIES, DUMMY_LAYOUT_ID } from '../../utils/appDefaults';
import { createTimelineTracks, TimelineTracks } from '../../utils/timelines';
import { localAtom } from '../localStorageState';
import { validCountryCodeFilter, validEnumArrayFilter, validEnumFilter } from '../../utils/atomHelpers';
import { FsUserInfo } from '../../utils/types/fsUserInfo';
import { KeysOfUnion, ThumbnailOrientation } from '../../utils/types/genericTypes';
import { CollectionsDataManager, LokaliseDataManager } from '../../data-layer';

export const PAST_LAYOUTS = 'pastLayouts';
export const FUTURE_LAYOUTS = 'futureLayouts';
export const DRAFT_LAYOUTS = 'draftLayouts';

export enum LAYOUT_VIEW_TYPE {
  VIEW_SCHEDULE = 'SCHEDULE',
  VIEW_DRAFTS = 'DRAFTS'
}

export interface Layouts {
  [PAST_LAYOUTS]: PageLayoutResponseV2[];
  [FUTURE_LAYOUTS]: PageLayoutResponseV2[];
  [DRAFT_LAYOUTS]: PageLayoutResponseV2[];
}
export type WhichLayouts = 'pastLayouts' | 'futureLayouts' | 'draftLayouts';
export type UiModuleBody = PageLayoutBodyV2['uiModules'][number];
export type HeroUiModuleItem = HeroUiModuleBody['heroContents'][number];

export interface LayoutInfoBlock {
  index: number;
  which: WhichLayouts;
}

export interface LiveLayoutMap {
  layout: PageLayoutResponseV2;
  countries: string[];
}

export const withLayouts = atom<Layouts | undefined>({
  key: 'layouts.layouts',
  default: undefined
});

export const withLayoutsType = localAtom<LayoutType>({
  key: 'layouts.layoutsType',
  default: LayoutType.PAGE,
  filter: validEnumFilter(LayoutType)
});

export const withLayoutsTypeLowercase = selector<'page' | 'menu'>({
  key: 'layouts.layoutsTypeLowercase',
  get: ({ get }) => {
    const layoutsType = get(withLayoutsType);
    if (layoutsType) {
      return layoutsType.toLowerCase() as 'page' | 'menu';
    }
    return 'page';
  }
});

export const withLayoutsView = selector<LAYOUT_VIEW_TYPE | undefined>({
  key: 'layouts.layoutView',
  get: ({ get }) => {
    const selectedLayout = get(withSelectedLayout);
    if (!selectedLayout) return undefined;
    return selectedLayout.startTime ? LAYOUT_VIEW_TYPE.VIEW_SCHEDULE : LAYOUT_VIEW_TYPE.VIEW_DRAFTS;
  }
});

export const withLiveLayouts = atom<Record<string, LiveLayoutMap> | undefined>({
  key: 'layouts.liveLayouts',
  default: undefined
});

export const withTimelineTracks = atom<Record<string, TimelineTracks> | undefined>({
  key: 'layouts.timelineTracks',
  default: undefined
});

export const withNumTimelineTracks = atom<number>({
  key: 'layouts.numTimelineTracks',
  default: 1
});

export const withAllLayouts = selector<Layouts | undefined>({
  key: 'layouts.allLayouts',
  get: ({ get }) => get(withLayouts),
  set: ({ get, set }, newLayouts) => {
    let layoutsTransformed: Layouts | undefined;
    if (newLayouts) {
      // Set the timeline tracks
      const timelineTracks = createTimelineTracks(newLayouts as Layouts, get(withLayoutSelectedCountries));
      set(withTimelineTracks, timelineTracks.tracksById);
      set(withNumTimelineTracks, timelineTracks.maxLength);
      // Set the layout info
      const layoutInfo: Record<string, LayoutInfoBlock> = {};
      const whichLayouts: WhichLayouts[] = [DRAFT_LAYOUTS, PAST_LAYOUTS, FUTURE_LAYOUTS];
      layoutsTransformed = {
        [PAST_LAYOUTS]: [],
        [FUTURE_LAYOUTS]: [],
        [DRAFT_LAYOUTS]: []
      };
      whichLayouts.forEach((which: WhichLayouts) => {
        (newLayouts as Layouts)[which].forEach((element, index) => {
          layoutInfo[element.id] = {
            index,
            which
          };
          (layoutsTransformed as Layouts)[which].push(element);
        });
      });
      set(withLayoutInfo, layoutInfo);
    }
    // Set the actual layouts atom
    set(withLayouts, layoutsTransformed);
  }
});

export const withLayoutInfo = atom<Record<string, LayoutInfoBlock>>({
  key: 'layouts.layoutInfo',
  default: {}
});

export const withPastLayoutsMeta = atom<PaginationMeta | undefined>({
  key: 'layouts.pastLayoutsMeta',
  default: undefined
});

export const withHasMorePastLayouts = selector<boolean>({
  key: 'layouts.hasMorePastLayouts',
  get: ({ get }) => {
    const meta = get(withPastLayoutsMeta);
    if (!meta) return true;
    return meta.hasNextPage;
  }
});

export const withLoadingPastLayouts = atom<boolean>({
  key: 'layouts.loadingPastLayouts',
  default: false
});

export const withLayoutSelectedCountries = localAtom<string[]>({
  key: 'layouts.selectedCountries',
  default: DEFAULT_LAYOUTS_COUNTRIES,
  filter: validCountryCodeFilter
});

export const withLayoutSelectedCountriesParam = selector({
  key: 'layouts.selectedCountriesParam',
  get: ({ get }) => get(withLayoutSelectedCountries).join('-')
});

export const withSelectedMenuLayoutId = localAtom({
  key: 'layouts.selectedMenuLayoutId',
  default: DUMMY_LAYOUT_ID
});

export const withSelectedPageLayoutId = localAtom({
  key: 'layouts.selectedPageLayoutId',
  default: DUMMY_LAYOUT_ID
});

export const withSelectedSpecialLayoutId = localAtom({
  key: 'layouts.selectedSpecialLayoutId',
  default: DUMMY_LAYOUT_ID
});

export const withSelectedPromoLayoutId = localAtom({
  key: 'layouts.selectedPromoLayoutId',
  default: DUMMY_LAYOUT_ID
});

const layoutIdAtomsByType = {
  [LayoutType.PAGE]: withSelectedPageLayoutId,
  [LayoutType.MENU]: withSelectedMenuLayoutId,
  [LayoutType.SPECIAL]: withSelectedSpecialLayoutId,
  [LayoutType.PROMOS]: withSelectedPromoLayoutId
};

export const withSelectedLayoutId = selector({
  key: 'layouts.selectedLayoutId',
  get: ({ get }) => get(layoutIdAtomsByType[get(withLayoutsType)]),
  set: ({ set, get }, layoutId) => {
    set(layoutIdAtomsByType[get(withLayoutsType)], layoutId);
  }
});

export const withSavingLayout = atom({
  key: 'layouts.savingLayout',
  default: false
});

export const withLayoutDeletionPopup = atom({
  key: 'layouts.layoutDeletionPopup',
  default: false
});

export const withShouldSaveLayoutWithWarnings = atom<boolean>({
  key: 'layouts.shouldSaveLayoutWithWarnings',
  default: false
});

export const withSelectedWhichLayouts = selector<WhichLayouts>({
  key: 'layouts.selectedWhichLayouts',
  get: ({ get }) => {
    const selectedLayoutInfo = get(withLayoutInfo)[get(withSelectedLayoutId)];
    return selectedLayoutInfo ? selectedLayoutInfo.which : PAST_LAYOUTS;
  }
});

export const withSelectedLayout = atom<PageLayoutResponseV2 | undefined>({
  key: 'layouts.selectedLayout',
  default: undefined
});

export const withSelectedLayoutUIModules = selector<UiModuleBody[] | undefined>({
  key: 'layouts.layoutUIModules',
  get: ({ get }) => get(withSelectedLayout)?.uiModules,
  set: ({ set, get }, uiModules) => {
    const layout = get(withSelectedLayout);
    if (layout) set(withSelectedLayout, { ...layout, uiModules } as PageLayoutResponseV2);
  }
});

export type UiModuleSearchable = {
  moduleId: string;
  index: number;
  parentIndex?: number;
  text: string;
};
type UiModuleFields = KeysOfUnion<UiModuleBody>;
const extractSearchableText = (
  module: UiModuleBody,
  collection: ContentCollectionResponse | undefined,
  translations: LokaliseTranslation[] | undefined
) => {
  const searchableFields: UiModuleFields[] = ['title', 'moduleType', 'contentTreatment'];
  let searchText = '';
  for (const searchableField of searchableFields) {
    const field = module[searchableField as keyof UiModuleBody];
    if (field) {
      if (typeof field === 'object') {
        for (const key in field) {
          searchText += ';' + String((field as never)[key]);
        }
      } else {
        searchText += ';' + String(field);
      }
    }
  }
  if (collection) {
    searchText += ';' + collection.title[DocumentLocale.ES];
    if (collection.title[DocumentLocale.EN]) {
      searchText += ';' + collection.title[DocumentLocale.EN];
    }
    if (collection.title[DocumentLocale.PT]) {
      searchText += ';' + collection.title[DocumentLocale.PT];
    }
  }
  translations?.forEach((translation) => {
    searchText += ';' + translation.translation;
  });
  return searchText;
};

export const withSelectedLayoutUIModulesSearchable = selector<UiModuleSearchable[] | undefined>({
  key: 'layouts.layoutUIModulesSearchable',
  get: ({ get }) => {
    const collectionsState = CollectionsDataManager.state;
    const collections = get(collectionsState.withLatestPublishedCache);
    const translationsState = LokaliseDataManager.state;
    const translations = get(translationsState.withAllRecordsById);
    return get(withSelectedLayout)?.uiModules.map((module, index) => ({
      index,
      moduleId: module.moduleId as string,
      text: extractSearchableText(
        module,
        collections[(module as ContentListUiModuleBody).contentListId]?.object,
        translations[(module as MenuItemUiModuleBody).titleLokaliseKey as string]?.translations
      )
    }));
  }
});

export const withSearchFocusModuleId = atom<string | undefined>({
  key: 'layouts.searchFocusModuleId',
  default: undefined
});

export const withSelectedLayoutCountries = selector<string[] | undefined>({
  key: 'layouts.selectedLayoutCountries',
  get: ({ get }) => get(withSelectedLayout)?.countries
});

export const withPreviewSportId = atom<string | undefined>({
  key: 'layouts.previewSportId',
  default: undefined
});

export const withPreviewLeagueId = atom<string | undefined>({
  key: 'layouts.previewLeagueId',
  default: undefined
});

export const withPreviewCollectionId = atom<string | undefined>({
  key: 'layouts.previewCollectionId',
  default: undefined
});

export const withEntitlementsFilter = localAtom<EntitlementType[]>({
  key: 'layouts.entitlementsFilter',
  default: Object.values(EntitlementType),
  filter: validEnumArrayFilter(EntitlementType)
});

export const withPlatformsFilter = localAtom<TargetPlatform[]>({
  key: 'layouts.platformsFilter',
  default: Object.values(TargetPlatform),
  filter: validEnumArrayFilter(TargetPlatform)
});

export const withHeroPreviewOrientation = localAtom<ThumbnailOrientation>({
  key: 'layouts.heroPreviewOrientation',
  default: ThumbnailOrientation.LANDSCAPE,
  filter: validEnumFilter(ThumbnailOrientation)
});

export const withCheckedModuleIds = atom<Record<string, boolean>>({
  key: 'layouts.checkedModuleIds',
  default: {}
});

export const withCopiedModules = atom<UiModuleBody[]>({
  key: 'layouts.copiedModuleIds',
  default: []
});

export const withLayoutSplitPane = localAtom<number[]>({
  key: 'layouts.splitPane',
  default: []
});

export const withConcurrentLayoutEditors = atom<FsUserInfo[]>({
  key: 'layouts.concurrentLayoutEditors',
  default: []
});

export const withLayoutModulesSearchTerm = atom<string>({
  key: 'layouts.modulesSearchTerm',
  default: ''
});
