import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useForm } from 'react-hook-form';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useLocales, useTheme } from '../../../../../hooks';
import { CustomValidators } from '../../../../../utils/customValidators';
import Button from '../../../../shared/Button';
import Drawer from '../../../../shared/Drawer';
import InputController from '../../../../shared/InputController';
import { SavedSearchResponse } from '../../../../../API';
import { useData } from '../../../../../data-layer';
import { ObjectTreeView } from '../../../../shared/ObjectTreeView';
import { useRecoilValue } from 'recoil';
import { isFormValid } from '../../../../../utils/formHelpers';

const useStyles = makeStyles()((theme) => ({
  headerLeft: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  },
  footerButton: {
    minWidth: 190,
    marginRight: theme.spacing(4)
  }
}));

export const testIds = {
  root: 'saved-search-form.root',
  name: 'saved-search-form.name',
  formBody: 'saved-search-form.form-body',
  cancelButton: 'saved-search-form.cancel-button',
  saveButton: 'saved-search-form.save-button',
  headerLeft: 'saved-search-form.header-left'
};

export interface ISavedSearchFormProps {
  onClose: () => void;
}

function SavedSearchForm({ onClose }: ISavedSearchFormProps): JSX.Element {
  const {
    savedSearches: {
      state: { withFormMetadata },
      hook: useSavedSearches
    }
  } = useData();

  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const [isSaving, setIsSaving] = useState(false);

  const formMetadata = useRecoilValue(withFormMetadata);
  const savedSearch = formMetadata.record;

  const { handleSubmit, control, reset } = useForm<SavedSearchResponse>({
    defaultValues: savedSearch
  });

  useEffect(() => {
    reset(savedSearch);
  }, [savedSearch]);

  const onSubmit = async (updatedSavedSearch: SavedSearchResponse) => {
    if (!(await isFormValid(formRef))) return;
    setIsSaving(true);
    const savedSavedSearch = await useSavedSearches.save(updatedSavedSearch);
    setIsSaving(false);

    if (!savedSavedSearch) {
      return;
    }

    handleOnClose();
  };

  const handleOnClose = () => {
    if (!isSaving) {
      useSavedSearches.closeForm();
      onClose();
    }
  };

  const renderSavedSearchForm = () => (
    <Drawer
      data-testid={testIds.root}
      open={formMetadata.isShowingForm}
      formRef={formRef}
      onClose={handleOnClose}
      headerLeft={
        <div className={classes.headerLeft} data-testid={testIds.headerLeft}>
          <Typography variant="h6">
            {t(`collections.${!formMetadata.isNew ? 'edit_saved_search' : 'new_saved_search'}`)}
          </Typography>
        </div>
      }
      footerLeft={
        <>
          <Button
            onClick={handleSubmit(onSubmit)}
            loading={isSaving}
            className={classes.footerButton}
            data-testid={testIds.saveButton}
          >
            {t('general.save')}
          </Button>
        </>
      }
      footerRight={
        <Button
          color="grey"
          disabled={isSaving}
          className={classes.footerButton}
          onClick={handleOnClose}
          data-testid={testIds.cancelButton}
        >
          {t('general.cancel')}
        </Button>
      }
    >
      <div className={classes.formBody} data-testid={testIds.formBody}>
        <InputController
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextValidator
              fullWidth
              name="name"
              color={formControlColor}
              value={value}
              onChange={onChange}
              label={t('general.name')}
              validators={[CustomValidators.requiredIfDefined]}
              errorMessages={[t('general.field_is_required')]}
              data-testid={testIds.name}
            />
          )}
        />
      </div>
      {savedSearch && <ObjectTreeView title={t('general.search')} object={savedSearch.query} />}
    </Drawer>
  );

  return renderSavedSearchForm();
}

export default SavedSearchForm;
