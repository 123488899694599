import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFilters, useLocales, useNotifications } from '../../../../hooks';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DynamicDateWithFallback, GetAllMediaQuery, MediaExportQuery, PaginationMeta } from '../../../../API';
import { omit } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { withSelectedLayout } from '../../../../state/Layouts';
import Pagination from '../../../shared/Pagination';
import { useData } from '../../../../data-layer';
import { useLifecycle } from '../../../../hooks/General/useLifecycle';
import { toStartOfDayISO, toEndOfDayISO } from '../../../../utils/dateHelpers';
import ContentSearchResults from './ContentSearchResults';
import ContentSearchFilters, { ContentSearchWithFilters, GetAllMediaQueryParams } from './ContentSearchFilters';
import { toBoolean } from '../../../../utils/textUtils';

interface IContentSearchProps {
  isContentPicker?: boolean;
  isForLayout?: boolean;
  onContentPicked?: (contentId: string) => void;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  searchResultsContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  bottomSection: {
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
    padding: theme.spacing(3, 4)
  }
}));

export const testIds = {
  root: 'content-search.root'
};

const MAX_CSV_EXPORT_RESULTS = 10000;

function ContentSearch({ isContentPicker, isForLayout, onContentPicked }: IContentSearchProps): JSX.Element {
  const {
    media: {
      state: {
        withSearchParams,
        withSearchResultsIds,
        withContentGroups,
        withContentTypes,
        withContentTypesAllowed,
        withContentVertical,
        withRating
      },
      hook: { searchMedia, exportMediaToCSV }
    }
  } = useData();

  const { classes } = useStyles();
  const { getFilter } = useFilters();
  const { willUnmount } = useLifecycle();
  const { notifyInfo, notifySuccess } = useNotifications();
  const { t } = useLocales();

  const [contentTypes, setContentTypes] = useRecoilState(withContentTypes);
  const contentTypesAllowed = useRecoilValue(withContentTypesAllowed);
  const [contentGroups, setContentGroups] = useRecoilState(withContentGroups);
  const [contentVertical, setContentVertical] = useRecoilState(withContentVertical);
  const [rating, setRating] = useRecoilState(withRating);

  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(withSearchParams);

  const [isLoading, setIsLoading] = useState(false);
  const [queryData, setQueryData] = useState<GetAllMediaQuery>();
  const [mediaList, setMediaList] = useRecoilState(withSearchResultsIds);
  const selectedLayout = useRecoilValue(withSelectedLayout); // TODO: data-layer convert

  const formMethods = useForm<ContentSearchWithFilters>();
  const { handleSubmit, reset, setValue, getValues } = formMethods;
  const [meta, setMeta] = useState<PaginationMeta>();

  const getContentTypes = async () => {
    const types = await getFilter('content', 'contentType');
    if (types) {
      let typesFiltered = types;
      if (contentTypesAllowed?.length > 0) {
        typesFiltered = types.filter((type) => contentTypesAllowed.includes(type.key.toUpperCase()));
      }
      setContentTypes(typesFiltered.map((type) => type.key));
    }
  };

  const getContentGroups = async () => {
    const types = await getFilter('content', 'contentGroupsDefault');
    if (types) setContentGroups(types.map((type) => type.key));
  };

  const getContentVertical = async () => {
    const types = await getFilter('content', 'contentVertical');
    if (types) setContentVertical(types.map((type) => type.key));
  };

  const getRating = async () => {
    const types = await getFilter('content', 'ratings.ratingValue');
    if (types) setRating(types.map((type) => type.key));
  };

  useEffect(() => {
    if (isForLayout) {
      const selectedLayoutCountries = selectedLayout?.countries;
      const countrySearchParams = { ...getValues(), country: selectedLayoutCountries };
      reset(countrySearchParams);
    }
  }, [selectedLayout]);

  useEffect(() => {
    getContentTypes();
  }, [contentTypesAllowed]);

  useEffect(() => {
    reset(recoilSearchParams);
    // Only search if we don't already have results
    if (!mediaList.length) {
      onSubmit();
    }
    if (!contentGroups.length) {
      getContentGroups();
    }
    if (!contentTypes.length) {
      getContentTypes();
    }
    if (!contentVertical.length) {
      getContentVertical();
    }
    if (!rating.length) {
      getRating();
    }
  }, []);

  const handleExport = async () => {
    setIsLoading(true);
    const query = { ...queryData, maxResults: MAX_CSV_EXPORT_RESULTS } as MediaExportQuery;
    await exportMediaToCSV(query);
    setIsLoading(false);
    if (meta && meta.totalDocs <= MAX_CSV_EXPORT_RESULTS) {
      notifySuccess(t('downloaded_successfully'));
    } else {
      notifyInfo(t('epg.channels.downloaded_with_truncation'));
    }
  };

  const transformDateRangeFilters = (data: GetAllMediaQueryParams): GetAllMediaQuery => {
    const mapIsoDateInDynamicDate = (date?: DynamicDateWithFallback, mapCallback = toStartOfDayISO) => {
      return date?.isoDate || date?.dynamicDate
        ? {
            ...date,
            isoDate: date?.isoDate ? mapCallback(date.isoDate) : date?.isoDate
          }
        : undefined;
    };
    return omit(
      {
        ...data,
        publishWindowStartDateMinDynamic: mapIsoDateInDynamicDate(data.publishWindowStartDate?.startDate),
        publishWindowStartDateMaxDynamic: mapIsoDateInDynamicDate(data.publishWindowStartDate?.endDate, toEndOfDayISO),
        publishWindowEndDateMinDynamic: mapIsoDateInDynamicDate(data.publishWindowEndDate?.startDate),
        publishWindowEndDateMaxDynamic: mapIsoDateInDynamicDate(data.publishWindowEndDate?.endDate, toEndOfDayISO),
        ingestDateMinDynamic: mapIsoDateInDynamicDate(data.ingestDate?.startDate),
        ingestDateMaxDynamic: mapIsoDateInDynamicDate(data.ingestDate?.endDate, toEndOfDayISO)
      },
      ['publishWindowStartDate', 'publishWindowEndDate', 'ingestDate']
    );
  };

  const transformBooleanFilters = (data: GetAllMediaQueryParams): GetAllMediaQuery => {
    return {
      ...data,
      isPremium: toBoolean(data.isPremium as unknown as string)
    };
  };

  const transformCountryFilters = (data: GetAllMediaQueryParams): GetAllMediaQuery => ({
    ...data,
    country: data.country?.length ? data.country : undefined
  });

  const handleSearch = async (data: ContentSearchWithFilters) => {
    if (!willUnmount.current) setIsLoading(true);
    const { _filters, ...searchData } = data;
    const newQueryData = transformBooleanFilters(transformDateRangeFilters(transformCountryFilters(searchData)));
    setQueryData(newQueryData);
    const response = await searchMedia(newQueryData);

    if (!willUnmount.current) setIsLoading(false);
    if (!response) {
      setMediaList([]);
      return;
    }
    if (!willUnmount.current) {
      if (!response.results) return;
      setMediaList(response?.results?.map((media) => media.contentId));
      const params = { ...searchData, limit: response.meta.limit, page: response.meta.page, _filters };
      setRecoilSearchParams(params);
      setMeta(response.meta);
      reset(params);
    }
  };

  const handleNewSearch = (data: ContentSearchWithFilters) => handleSearch({ ...data, page: 1 });

  const onSubmit = () => {
    handleSubmit(handleSearch)();
  };

  const onSubmitNew = (evt?: React.FormEvent<HTMLElement>) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    handleSubmit(handleNewSearch)();
  };

  return (
    <FormProvider {...formMethods}>
      <div data-testid={testIds.root} className={classes.root}>
        <ContentSearchFilters
          mediaList={mediaList}
          paginationMeta={meta}
          onSubmit={onSubmitNew}
          onExport={handleExport}
        />
        <div className={classes.searchResultsContainer}>
          <ContentSearchResults
            isContentPicker={isContentPicker}
            onContentPicked={onContentPicked}
            isLoading={isLoading}
            mediaList={mediaList}
          />
          <div className={classes.bottomSection}>
            <Pagination
              limitOptions={[20, 50, 100]}
              queryMetaData={meta}
              onPageChange={(newPage: number) => {
                reset();
                setValue('page', newPage);
                onSubmit();
              }}
              onResultsChange={(newLimit: number) => {
                reset();
                setValue('page', 1);
                setValue('limit', newLimit);
                onSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export default ContentSearch;
