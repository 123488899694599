/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
import { chunk, cloneDeep, debounce, flatten, isEqual, uniq } from 'lodash-es';
import { DateTime } from 'luxon';
import { RecoilState, useRecoilCallback, useSetRecoilState } from 'recoil';
import { IDataManagerProps } from '.';
import { IsDeletableResponse, OperationIds, VersionedDocumentBody, VersionedDocumentStatus } from '../../API';
import { ErrorDetails, Paginated, useLocales } from '../../hooks';
import generateId from '../../utils/generateId';
import { CacheManager } from './CacheManager';
import { DataManagerHelpers } from './DataManagerHelpers';
import { CachedRecord, DataManagerStateFullReturnType } from './DataManagerState';

export const DEFAULT_PAGINATION_RPP = 100;
export const DEFAULT_REVISION_RPP = 10;
export const MAX_GET_BY_IDS = 100;

export interface IDataManagerHookProps<R, B = R> extends IDataManagerProps<R, B> {
  state: DataManagerStateFullReturnType<R, B>;
}

type HandleApiErrorType = (err: any, action: string, details?: ErrorDetails | undefined) => void;

export interface DataManagerHookReturnType<R, B = R> {
  getAll: (args?: any[]) => Promise<R[] | undefined>;
  getOne?: (args: any) => Promise<R[]>;
  getSome?: (query: any) => Promise<R[]>;
  fetchPaginated?: (limit: number, page: number, ...args: any[]) => Promise<Paginated<R> | undefined>;
  fetchPaginatedPost?: <T extends { limit?: number; page?: number }>(params: T) => Promise<Paginated<R> | undefined>;
  getBucket: (bucketId: string) => Promise<R[] | undefined>;
  getById: (id: string, includeDeleted?: boolean) => Promise<R | undefined>;
  getByIds: (ids: string[], includeDeleted?: boolean) => Promise<R[] | undefined>;
  getLatestPublishedById: (id: string, includeDeleted?: boolean) => Promise<R | undefined>;
  getLatestPublishedByIds: (ids: string[], includeDeleted?: boolean) => Promise<R[] | undefined>;
  getRevisions: (id: string, limit?: number, page?: number) => Promise<Paginated<R> | undefined>;
  getCachedRecordsToFetch: (ids: string[]) => Promise<CachedRecord<R>[]>;
  new: (...args: any) => void;
  edit: (id: string) => Promise<void>;
  clone: (id: string) => Promise<R | undefined>;
  closeForm: () => void;
  setSelected: (id: string | undefined) => Promise<void>;
  create: (record: R | B, suppressSuccessMessage?: boolean, ...args: any[]) => Promise<R | undefined>;
  update: (id: string, record: R, suppressSuccessMessage?: boolean, ...args: any[]) => Promise<R | undefined>;
  save: (record: R | B, suppressSuccessMessage?: boolean) => Promise<R | undefined>;
  remove: (id: string, handleError?: HandleApiErrorType) => Promise<boolean>;
  validateDeletion: (id: string) => Promise<IsDeletableResponse | undefined>;
  publish: (id: string) => Promise<R | undefined>;
  createAndPublish: (record: R) => Promise<R | undefined>;
  updateAndPublish: (id: string, record: R) => Promise<R | undefined>;
  saveAndPublish: (record: R) => Promise<R | undefined>;
  queueIdToFetch: (id: string, includeDeleted?: boolean) => void;
  queueLatestPublishedIdToFetch: (id: string, includeDeleted?: boolean) => void;
  removeFromCache: (id: string) => Promise<boolean>;
  addRecordToIdsList: (record: R, recordIds: CachedRecord<string[]>) => Promise<string[]>;
}

export function useGetRecoilState<T>(state: RecoilState<T>): () => Promise<T> {
  return useRecoilCallback(({ snapshot }) => async () => {
    return snapshot.getPromise(state);
  });
}

// Hook
export function DataManagerHook<R, B = R>({
  name,
  state,
  idField,
  isVersionedEntity,
  useApiHook,
  generateNew,
  toString,
  useUpdateForCreate,
  selectOnCreation = true,
  sortFunctionInitializer,
  bucketIdToQueryParams,
  getRecordBucketIds,
  paginationLimit = DEFAULT_PAGINATION_RPP,
  generateEntityId = generateId,
  operationIdGetAll = OperationIds.GET_ALL
}: IDataManagerHookProps<R, B>): DataManagerHookReturnType<R, B> {
  // hooks
  const api = useApiHook();
  const locales = useLocales();
  const sortFunction = sortFunctionInitializer?.(locales);

  // Error and success handling

  const { getRecordString, handleApiError, handleSuccess, missingActionError, newRelicLog } = DataManagerHelpers({
    name,
    toString
  });

  // setters
  const setCache = useSetRecoilState(state.withDataCache);
  const setLatestPublishedCache = useSetRecoilState(state.withLatestPublishedCache);
  const setAllRecordIds = useSetRecoilState(state.withAllRecordIds);
  const setFormMetaData = useSetRecoilState(state.withFormMetadata);
  const setSelectedId = useSetRecoilState(state.withSelectedId);
  const setIsFetching = useSetRecoilState(state.withIsFetching);
  const setIsSaving = useSetRecoilState(state.withIsSaving);
  const setIsPublishing = useSetRecoilState(state.withIsPublishing);
  const setIsDeleting = useSetRecoilState(state.withIsDeleting);
  const setIsValidatingDeletion = useSetRecoilState(state.withIsValidatingDeletion);
  const setSelectedRecord = useSetRecoilState(state.withSelected);
  const setBuckets = useSetRecoilState(state.withRecordBucketsById);

  // getters
  const getAllRecordIds = useGetRecoilState(state.withAllRecordIds);
  const getCache = useGetRecoilState(state.withDataCache);
  const getLatestPublishedCache = useGetRecoilState(state.withLatestPublishedCache);
  const getSelectedId = useGetRecoilState(state.withSelectedId);
  const getBuckets = useGetRecoilState(state.withRecordBucketsById);
  const getFormMetadata = useGetRecoilState(state.withFormMetadata);

  // Cache management

  const {
    addRecordsToCache,
    addRecordToCache,
    addLatestPublishedRecordToCache,
    addLatestPublishedRecordsToCache,
    flattenCache,
    isStale
  } = CacheManager<R>(idField, setCache, setLatestPublishedCache);

  const isDraft: (record: R) => boolean = (record: R) => {
    return !!isVersionedEntity && (record as VersionedDocumentBody).status === VersionedDocumentStatus.DRAFT;
  };

  // Fetch Operations

  const idsToFetch: Set<string> = new Set();

  const queueIdToFetch = (id: string, includeDeleted = false) => {
    idsToFetch.add(id);
    fetchAllContentsFromQueue(includeDeleted);
  };

  const fetchAllContentsFromQueue = debounce((includeDeleted: boolean) => {
    const idsCopy = [...idsToFetch];
    idsToFetch.clear();
    if (idsCopy.length === 1) {
      fetchContentById(idsCopy[0], includeDeleted);
    } else {
      fetchContentsByIds(idsCopy, includeDeleted);
    }
  }, 50);

  const latestPublishedIdsToFetch: Set<string> = new Set();

  const queueLatestPublishedIdToFetch = (id: string, includeDeleted = false) => {
    latestPublishedIdsToFetch.add(id);
    fetchAllLatestPublishedFromQueue(includeDeleted);
  };

  const fetchAllLatestPublishedFromQueue = debounce((includeDeleted: boolean) => {
    const idsCopy = [...latestPublishedIdsToFetch];
    latestPublishedIdsToFetch.clear();
    if (idsCopy.length === 1) {
      fetchLatestPublishedById(idsCopy[0], includeDeleted);
    } else {
      fetchLatestPublishedByIds(idsCopy, includeDeleted);
    }
  }, 50);

  const getAll = async (args: any[] = []) => {
    const allRecordIds = await getAllRecordIds();
    const cache = await getCache();

    if (!allRecordIds || isStale(allRecordIds)) {
      return fetchAllForAll(args);
    }
    return allRecordIds.object.map((id) => cache[id].object);
  };

  const fetchAll = async (storeRecordIds: (recordIds: string[]) => Promise<void>, args: any[]) => {
    const action =
      api.getAll && operationIdGetAll === OperationIds.GET_ALL ? OperationIds.GET_ALL : OperationIds.GET_PAGINATED;
    try {
      setIsFetching(true);
      let allRecords: R[] = [];
      if (api.getAll && action === OperationIds.GET_ALL) {
        const {
          data: { body }
        } = await api.getAll();
        allRecords = body;
      } else {
        if (!api.getPaginated) throw missingActionError(action);
        let page = 1;
        let response;
        do {
          response = await api.getPaginated(paginationLimit, page, ...args);
          allRecords.push(...response.data.body.results);
          page++;
        } while (response.data.body.meta.nextPage);
      }
      if (sortFunction) allRecords.sort(sortFunction);
      addRecordsToCache(allRecords);
      const dataIds = allRecords.map((record) => String(record[idField]));
      await storeRecordIds(dataIds);
      return allRecords;
    } catch (err) {
      handleApiError(err, action);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchAllForAll = async (args: any[]) => {
    const handleRecordIds = async (recordIds: string[]) => {
      setAllRecordIds({
        lastUpdated: DateTime.now(),
        object: recordIds
      });
    };
    return await fetchAll(handleRecordIds, args);
  };

  const fetchAllForBucket = async (bucketId: string, args: any[]) => {
    const handleRecordIds = async (recordIds: string[]) => {
      const buckets = await getBuckets();
      setBuckets({
        ...buckets,
        [bucketId]: {
          lastUpdated: DateTime.now(),
          object: recordIds
        }
      });
    };
    return await fetchAll(handleRecordIds, args);
  };

  const filterAndAddPublishedRecords = (records: R[]) => {
    // Add all records that aren't drafts to the Latest Published record cache
    if (isVersionedEntity) {
      addLatestPublishedRecordsToCache(records.filter((record) => !isDraft(record)));
    }
  };

  const fetchPaginated = async (limit: number, page: number, args: any[]) => {
    const action = OperationIds.GET_PAGINATED;
    try {
      if (!api.getPaginated) throw missingActionError(action);
      setIsFetching(true);
      const response = await api.getPaginated(limit, page, ...args);
      addRecordsToCache(response.data.body.results);
      filterAndAddPublishedRecords(response.data.body.results);
      return response.data.body;
    } catch (err) {
      handleApiError(err, action);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchPaginatedPost = async <T extends { limit?: number; page?: number }>(params: T) => {
    const action = 'getPaginatedPost';
    try {
      if (!api.getPaginatedPost) throw missingActionError(action);
      const response = await api.getPaginatedPost(params);
      addRecordsToCache(response.data.body.results);
      filterAndAddPublishedRecords(response.data.body.results);
      return response.data.body;
    } catch (err) {
      handleApiError(err, action);
    }
  };

  const getBucket = async (bucketId: string) => {
    if (!bucketIdToQueryParams) {
      console.error(`You must provide a 'bucketIdToQueryParams' prop to the '${name}' data manager`);
      return;
    }
    const fetchArgs = bucketIdToQueryParams(bucketId);
    const bucketIds = await getBuckets();
    if (!bucketIds[bucketId] || isStale(bucketIds[bucketId])) {
      return fetchAllForBucket(bucketId, fetchArgs);
    }
    const cache = await getCache();
    return bucketIds[bucketId].object?.map((id) => cache[id].object);
  };

  const getById = async (id: string, includeDeleted = false) => {
    const cache = await getCache();
    if (cache[id] && !isStale(cache[id])) {
      return cache[id].object;
    }
    return await fetchContentById(id, includeDeleted);
  };

  const fetchContentById = async (id: string, includeDeleted = false) => {
    const action = isVersionedEntity ? OperationIds.GET_LATEST_REVISION : OperationIds.GET_BY_ID;
    try {
      if (!api[action]) throw missingActionError(action);
      const {
        data: { body }
      } = await api[action]!(id, includeDeleted);
      addRecordToCache(body);
      // If it's not a draft, also add it to the latest published record cache
      if (isVersionedEntity && !isDraft(body)) {
        addLatestPublishedRecordToCache(body);
      }
      return body;
    } catch (err) {
      handleApiError(err, action, id);
    }
  };

  const getLatestPublishedById = async (id: string, includeDeleted = false) => {
    const cache = await getLatestPublishedCache();
    if (cache[id] && !isStale(cache[id])) {
      return cache[id].object;
    }
    return await fetchLatestPublishedById(id, includeDeleted);
  };

  const fetchLatestPublishedById = async (id: string, includeDeleted = false) => {
    const action = OperationIds.GET_LATEST_PUBLISHED_REVISION;
    try {
      if (!api[action]) throw missingActionError(action);
      const response = await api[action]!(id, includeDeleted);
      addLatestPublishedRecordToCache(response.data.body);
      return response.data.body;
    } catch (err) {
      handleApiError(err, action, id);
    }
  };

  const getByIds = async (ids: string[], includeDeleted = false) => {
    if (!ids.length) return;
    if (ids.length === 1) {
      const record = await getById(ids[0], includeDeleted);
      return record ? [record] : undefined;
    }
    if (!ids.length) return;
    const cachedRecords = await getCachedRecordsToFetch(ids);
    if (cachedRecords.length > 0) {
      return flattenCache(cachedRecords);
    }
    const fetchedRecords = await fetchContentsByIds(ids, includeDeleted);
    if (!fetchedRecords) {
      return flattenCache(cachedRecords);
    }
    return ids.map((id, i) => cachedRecords[i]?.object ?? fetchedRecords[id]?.object);
  };

  const getLatestPublishedByIds = async (ids: string[], includeDeleted = false) => {
    if (!ids.length) return;
    if (ids.length === 1) {
      const record = await getLatestPublishedById(ids[0], includeDeleted);
      return record ? [record] : undefined;
    }
    const cache = await getLatestPublishedCache();

    const cachedRecords = ids.map((id) => cache[id]);
    const idsToFetch = ids.filter((id, i) => !cachedRecords[i] || isStale(cachedRecords[i]));
    if (!idsToFetch.length) {
      return flattenCache(cachedRecords);
    }
    const fetchedRecords = await fetchLatestPublishedByIds(ids, includeDeleted);
    if (!fetchedRecords) {
      return flattenCache(cachedRecords);
    }
    return ids.map((id, i) => cachedRecords[i]?.object ?? fetchedRecords[id]?.object);
  };

  const getCachedRecordsToFetch = async (ids: string[]) => {
    const cache = await getCache();
    const cachedRecords = ids.map((id) => cache[id]);
    const idsToFetch = ids.filter((id, i) => !cachedRecords[i] || isStale(cachedRecords[i]));
    if (!idsToFetch.length) {
      return cachedRecords;
    }

    return [];
  };

  const fetchIdsByAction = async (
    ids: string[],
    includeDeleted = false,
    action: OperationIds.GET_LATEST_REVISIONS_BY_IDS | OperationIds.GET_BY_IDS
  ) => {
    try {
      if (!api[action]) {
        throw missingActionError(action);
      }
      const chunkedIds = chunk(ids, MAX_GET_BY_IDS);
      const promises = chunkedIds.map((cIds) => api[action]!(cIds, includeDeleted));
      const responses = await Promise.all(promises);
      const records = flatten(responses.map((response) => response.data.body));
      return addRecordsToCache(records); // Help out getByIds by returning them already as a hash
    } catch (err) {
      handleApiError(err, action, ids);
    }
  };

  const fetchContentsByIds = async (ids: string[], includeDeleted = false) => {
    const action = isVersionedEntity ? OperationIds.GET_LATEST_REVISIONS_BY_IDS : OperationIds.GET_BY_IDS;
    return fetchIdsByAction(ids, includeDeleted, action);
  };

  const fetchLatestPublishedByIds = async (ids: string[], includeDeleted = false) => {
    const action = OperationIds.GET_LATEST_PUBLISHED_REVISIONS_BY_IDS;
    try {
      if (!api[action]) {
        throw missingActionError(action);
      }
      const chunkedIds = chunk(ids, MAX_GET_BY_IDS);
      const promises = chunkedIds.map((cIds) => api[action]!(cIds, includeDeleted));
      const responses = await Promise.all(promises);
      const records = flatten(responses.map((response) => response.data.body));
      return addLatestPublishedRecordsToCache(records);
    } catch (err) {
      handleApiError(err, action, ids);
    }
  };

  // Selection

  const setSelected = async (id: string | undefined) => {
    setSelectedId(id || '');
    if (!id) return setSelectedRecord(undefined);
    const cache = await getCache();
    if (!cache[id]) return;
    setSelectedRecord(cloneDeep(cache[id].object));
  };

  // Form operations

  const new_ = (...args: any) => {
    const newRecord = generateNew?.(...args);
    if (!newRecord) {
      // eslint-disable-next-line no-console
      console.warn(`You must provide a 'generateNew' prop to the '${name}' data manager`);
      return;
    }
    setFormMetaData({
      isShowingForm: true,
      isNew: true,
      record: newRecord
    });
  };

  const edit = async (id: string) => {
    const cache = await getCache();
    const cachedRecord = cache[id];
    if (!cachedRecord) return;
    setFormMetaData({
      isShowingForm: true,
      isEditing: true,
      record: cloneDeep(cachedRecord.object)
    });
  };

  const clone = async (id: string): Promise<R | undefined> => {
    const cache = await getCache();
    const cachedRecord = cache[id];
    if (!cachedRecord) return;
    const clonedRecord: any = cloneDeep(cachedRecord.object);
    clonedRecord.entityId = generateEntityId();
    clonedRecord.revision = 0;
    clonedRecord.createdDate = undefined;
    clonedRecord.status = undefined;
    setFormMetaData({
      isShowingForm: true,
      isCloning: true,
      isNew: true,
      cloningRecord: cloneDeep(cachedRecord.object),
      record: clonedRecord // TODO: Remove any unrelated fields, may need to do more
    });
    return clonedRecord;
  };

  const closeForm = () => {
    setFormMetaData((formMetadata) => ({
      ...formMetadata,
      isShowingForm: false
    }));
  };

  // Saving and updating

  const addRecordToIdsList = async (record: R, recordIds: CachedRecord<string[]>) => {
    let newRecordIds: string[];
    if (sortFunction) {
      const cache = await getCache();
      const records: R[] = [record, ...recordIds.object.map((recordId) => cache[recordId]?.object)];
      records.sort(sortFunction);
      newRecordIds = records.map((record) => String(record[idField]));
    } else {
      newRecordIds = [String(record[idField]), ...recordIds.object];
    }
    return uniq(newRecordIds); // Remove duplicates, in the case of updating an existing record
  };

  const create = async (record: R | B, suppressSuccessMessage = false, ...args: any[]) => {
    const action = OperationIds.CREATE;
    try {
      if (!api.create) {
        if (useUpdateForCreate) {
          const id = (record as R)[idField] ? String((record as R)[idField]) : generateEntityId();
          const response = await update(id, { ...record, [idField]: id } as R, true);
          if (response && !suppressSuccessMessage) {
            handleSuccess(action, getRecordString(response));
          }
          return response;
        }
        throw missingActionError(action);
      }
      setIsSaving(true);
      const response = await api.create(record, ...args);
      newRelicLog(action, response.data.body);
      addRecordToCache(response.data.body);
      // Update "All Records" if they are set
      const allRecordIds = await getAllRecordIds();
      if (allRecordIds) {
        setAllRecordIds({
          lastUpdated: DateTime.now(),
          object: await addRecordToIdsList(response.data.body, allRecordIds)
        });
      }
      // Update buckets if we are using them
      if (getRecordBucketIds) {
        const recordBucketIds = getRecordBucketIds(response.data.body);
        const buckets = { ...(await getBuckets()) };
        // Iterate through buckets, and if the bucket already exists, add this record and sort
        for (const bucketId of recordBucketIds) {
          if (buckets[bucketId] && buckets[bucketId].object) {
            buckets[bucketId] = {
              lastUpdated: buckets[bucketId].lastUpdated,
              object: await addRecordToIdsList(response.data.body, buckets[bucketId] as CachedRecord<string[]>)
            };
          }
        }
        setBuckets(buckets);
      }
      // Select the record if we care
      if (selectOnCreation) {
        setTimeout(() => {
          setSelected(String(response.data.body[idField]));
        });
      }
      handleSuccess(action, getRecordString(response.data.body));
      return response.data.body;
    } catch (err) {
      handleApiError(err, action, [getRecordString(record)]);
    } finally {
      setIsSaving(false);
    }
  };

  const update = async (id: string, record: R, suppressSuccessMessage = false, ...args: any[]) => {
    const action = OperationIds.UPDATE;
    try {
      if (!api.update) throw missingActionError(action);
      setIsSaving(true);
      const response = await api.update(id, record, ...args);
      newRelicLog(action, response.data);
      addRecordToCache(response.data.body);
      if (!suppressSuccessMessage) handleSuccess(action, getRecordString(response.data.body));
      // Update "All Records" if they are set (would only matter if sort order has changed)
      const allRecordIds = await getAllRecordIds();
      if (allRecordIds) {
        setAllRecordIds({
          ...allRecordIds,
          object: await addRecordToIdsList(response.data.body, allRecordIds)
        });
      }
      // Update buckets if we are using them
      if (getRecordBucketIds) {
        const recordBucketIds = getRecordBucketIds(response.data.body);
        const buckets = { ...(await getBuckets()) };
        for (const bucketId of Object.keys(buckets)) {
          if (!buckets[bucketId] || !buckets[bucketId].object) continue;
          // If we have this record in a bucket where it is no longer included, remove it from that bucket
          if ((buckets[bucketId].object as string[]).indexOf(id) >= 0 && recordBucketIds.indexOf(bucketId) === -1) {
            buckets[bucketId] = {
              ...buckets[bucketId],
              object: buckets[bucketId].object?.filter((x) => x !== id)
            };
          }
          // If we have an existing bucket with this record, conditionally update it if the sort order has changed
          if (recordBucketIds.indexOf(bucketId) >= 0) {
            const newRecordsList = await addRecordToIdsList(
              response.data.body,
              buckets[bucketId] as CachedRecord<string[]>
            );
            // Only update if the sort order has changed
            if (!isEqual(newRecordsList, buckets[bucketId].object)) {
              buckets[bucketId] = {
                ...buckets[bucketId],
                object: newRecordsList
              };
            }
          }
        }
        setBuckets(buckets);
      }
      // Update the selected record if it is currently selected
      const selectedId = await getSelectedId();
      if (selectedId && selectedId === (response.data.body[idField] as unknown as string)) {
        setSelectedRecord(cloneDeep(response.data.body));
      }
      return response.data.body;
    } catch (err) {
      handleApiError(err, action, getRecordString(record));
    } finally {
      setIsSaving(false);
    }
  };

  const save = async (record: R | B, suppressSuccessMessage = false, ...args: any[]) => {
    const formMetadata = await getFormMetadata();
    if (formMetadata.isNew) {
      return await create(record, suppressSuccessMessage);
    }
    return await update(String((record as R)[idField]), record as R, suppressSuccessMessage, ...args);
  };

  const publish = async (id: string) => {
    const action = OperationIds.PUBLISH;
    try {
      if (!api.publish) throw missingActionError(action);
      setIsPublishing(true);
      const response = await api.publish(id);
      newRelicLog(action, response.data);
      addRecordToCache(response.data.body);
      addLatestPublishedRecordToCache(response.data.body);
      const selectedId = await getSelectedId();
      if (selectedId && selectedId === (response.data.body[idField] as unknown as string)) {
        setSelectedRecord(cloneDeep(response.data.body));
      }
      handleSuccess(action, getRecordString(response.data.body));
      return response.data.body;
    } catch (err) {
      const cache = await getCache();
      const details = cache[id] ? getRecordString(cache[id].object) : id;
      handleApiError(err, action, details);
    } finally {
      setIsPublishing(false);
    }
  };

  const createAndPublish = async (record: R | B) => {
    const createdRecord = await create(record, true);
    if (createdRecord) {
      return await publish(String(createdRecord[idField]));
    }
  };

  const updateAndPublish = async (id: string, record: R) => {
    if (await update(id, record, true)) {
      return await publish(id);
    }
  };

  const saveAndPublish = async (record: R | B) => {
    const formMetadata = await getFormMetadata();
    if (formMetadata.isNew) {
      return await createAndPublish(record);
    }
    return await updateAndPublish(String((record as R)[idField]), record as R);
  };

  const remove = async (id: string) => {
    const cache = await getCache();
    if (!cache[id]) return false;
    const recordToDelete = cache[id].object;
    const action = OperationIds.REMOVE;
    try {
      if (!api.remove) throw missingActionError(action);
      setIsDeleting(true);
      await api.remove(id);
      newRelicLog(action, recordToDelete);
      removeFromCache(id);
      handleSuccess(action, getRecordString(recordToDelete));
      return true;
    } catch (err) {
      handleApiError(err, action, [getRecordString(recordToDelete)]);
    } finally {
      setIsDeleting(false);
    }
    return false;
  };

  const validateDeletion = async (id: string) => {
    const cache = await getCache();
    if (!cache[id]) return;
    const recordToDelete = cache[id].object;
    const action = OperationIds.IS_DELETABLE;
    try {
      if (!api.isDeletable) throw missingActionError(action);
      setIsValidatingDeletion(true);
      const { data } = await api.isDeletable(id);
      return data.body;
    } catch (err) {
      handleApiError(err, action, [getRecordString(recordToDelete)]);
    } finally {
      setIsValidatingDeletion(false);
    }
  };

  // Revisions
  const getRevisions = async (id: string, limit?: number, page?: number) => {
    const action = OperationIds.GET_REVISIONS;
    try {
      if (!api.getRevisions) throw missingActionError(action);
      const response = await api.getRevisions(id, limit || DEFAULT_REVISION_RPP, page);
      return response.data.body;
    } catch (err) {
      handleApiError(err, action, [id]);
    }
  };

  const removeFromCache = async (id: string) => {
    const allRecordIds = await getAllRecordIds();
    const cache = await getCache();

    if (!cache[id]) return false;

    // Remove it from the list of all data if applicable
    if (allRecordIds) {
      setAllRecordIds({
        lastUpdated: DateTime.now(),
        object: allRecordIds.object.filter((iterateeId) => iterateeId !== id)
      });
    }

    // Remove it from buckets if applicable
    if (getRecordBucketIds) {
      const recordToDelete = cache[id].object;
      const recordBucketIds = getRecordBucketIds(recordToDelete);
      const buckets = { ...(await getBuckets()) };
      // Iterate through buckets, and if the bucket already exists, remove this record from that bucket
      for (const bucketId of recordBucketIds) {
        if (buckets[bucketId] && buckets[bucketId].object) {
          buckets[bucketId] = {
            lastUpdated: buckets[bucketId].lastUpdated,
            object: buckets[bucketId].object?.filter((recordId) => recordId !== id)
          };
        }
      }
      setBuckets(buckets);
    }

    // Remove it from the cache
    const newData = { ...cache };
    delete newData[id];
    setCache(newData);

    // Remove it from Latest Published cache if applicable
    if (isVersionedEntity) {
      const lpCache = await getLatestPublishedCache();
      const newLpData = { ...lpCache };
      delete newLpData[id];
      setLatestPublishedCache(newLpData);
    }

    return true;
  };

  return {
    getAll,
    getById,
    getByIds,
    getLatestPublishedById,
    getLatestPublishedByIds,
    getCachedRecordsToFetch,
    setSelected,
    fetchPaginated,
    fetchPaginatedPost,
    getBucket,
    new: new_,
    edit,
    clone,
    closeForm,
    create,
    update,
    save,
    remove,
    validateDeletion,
    publish,
    createAndPublish,
    updateAndPublish,
    saveAndPublish,
    queueIdToFetch,
    queueLatestPublishedIdToFetch,
    getRevisions,
    removeFromCache,
    addRecordToIdsList
  };
}
