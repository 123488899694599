/* IMPORTANT:
When adding a new asset type, be sure to add it to both AssetTypesEnum and AssetTypes.
If there are specific dimensions or image types, add them to AssetGuidelines in utils/assetGuidelines.ts
*/

import { AspectRatiosEnum } from './aspectRatios';

export enum AssetTypesEnum {
  // channel
  CHANNEL_LOGO = 'channel',
  CHANNEL_PORTRAIT = 'channel-portrait',
  CHANNEL_LANDSCAPE = 'channel-landscape',
  // collections
  COLLECTIONS_CARD = 'collection-card-image',
  COLLECTIONS_LANDSCAPE = 'collection-landscape-splash',
  COLLECTIONS_PORTRAIT = 'collection-portrait-splash',
  // hero
  HERO_LANDSCAPE = 'hero-landscape',
  HERO_PORTRAIT = 'hero-portrait',
  HERO_MOBILE = 'hero-mobile',
  HERO_LOGO = 'hero-logo',
  HERO_LOGO_PORTRAIT = 'hero-logo-portrait',
  HERO_CTV = 'hero-ctv',
  HERO_SPONSOR = 'sponsor-logo',
  // content list (collection module)
  CONTENT_LIST_LANDSCAPE = 'content-list-landscape',
  CONTENT_LIST_PORTRAIT = 'content-list-portrait',
  // sports
  SPORT_LANDSCAPE = 'sport-landscape',
  SPORT_PORTRAIT = 'sport-portrait',
  LEAGUE_LOGO = 'league/logo',
  LEAGUE_CARD = 'league/card',
  LEAGUE_SPLASH = 'league/splash',
  TEAM_LOGO = 'team-logo',
  // sports default assets
  SPORTS_DEFAULT_LEAGUE_BG = 'tournament/default-bgImage',
  SPORTS_DEFAULT_LEAGUE_SPLASH = 'tournament/default-splashImage',
  SPORTS_DEFAULT_LEAGUE_LOGO = 'tournament/default-logo',
  SPORTS_DEFAULT_TEAM_LOGO = 'team/default-logo',
  // promotion module
  PROMO_UPSELL_PORTRAIT = 'promotion/portrait-upsell',
  PROMO_UPSELL_LANDSCAPE = 'promotion/landscape-upsell',
  PROMO_UPSELL_DESKTOP = 'promotion/desktop-upsell',
  PROMO_UPSELL_MOBILE = 'promotion/mobile-upsell',
  PROMO_UPSELL_CTV = 'promotion/ctv-upsell',
  PROMO_UPSELL_TABLET = 'promotion/tablet-upsell',
  // live plus module
  LIVE_PLUS_TAKEOVER_PORTRAIT = 'live-plus/portrait-takeover',
  LIVE_PLUS_TAKEOVER_LANDSCAPE = 'live-plus/landscape-takeover',
  LIVE_PLUS_TAKEOVER_DESKTOP = 'live-plus/desktop-takeover',
  LIVE_PLUS_TAKEOVER_TABLET = 'live-plus/tablet-takeover',
  LIVE_PLUS_TAKEOVER_MOBILE = 'live-plus/mobile-takeover',
  LIVE_PLUS_TAKEOVER_CTV = 'live-plus/ctv-takeover',
  LIVE_PLUS_END_GAME_PORTRAIT = 'live-plus/portrait-end-game',
  LIVE_PLUS_END_GAME_LANDSCAPE = 'live-plus/landscape-end-game',
  LIVE_PLUS_END_GAME_DESKTOP = 'live-plus/desktop-end-game',
  LIVE_PLUS_END_GAME_TABLET = 'live-plus/tablet-end-game',
  LIVE_PLUS_END_GAME_MOBILE = 'live-plus/mobile-end-game',
  LIVE_PLUS_END_GAME_CTV = 'live-plus/ctv-end-game',
  // inline promo module
  INLINE_PROMO_PORTRAIT = 'inline-promo/portrait-fill',
  INLINE_PROMO_LANDSCAPE = 'inline-promo/landscape-fill',
  INLINE_PROMO_DESKTOP = 'inline-promo/desktop-fill',
  INLINE_PROMO_TABLET = 'inline-promo/tablet-fill',
  INLINE_PROMO_MOBILE = 'inline-promo/mobile-fill',
  INLINE_PROMO_CTV = 'inline-promo/ctv-fill',
  // account success module
  ACCOUNT_SUCCESS_PORTRAIT = 'account-success/portrait',
  ACCOUNT_SUCCESS_LANDSCAPE = 'account-success/landscape',
  ACCOUNT_SUCCESS_DESKTOP = 'account-success/desktop',
  ACCOUNT_SUCCESS_TABLET = 'account-success/tablet',
  ACCOUNT_SUCCESS_MOBILE = 'account-success/mobile',
  ACCOUNT_SUCCESS_CTV = 'account-success/ctv',
  ACCOUNT_SUCCESS_LOGO = 'account-success/logo',
  // one brand promo module
  ONE_BRAND_PROMO_LANDSCAPE = 'one-brand-promo/landscape',
  ONE_BRAND_PROMO_PORTRAIT = 'one-brand-promo/portrait',
  ONE_BRAND_PROMO_DESKTOP = 'one-brand-promo/desktop',
  ONE_BRAND_PROMO_MOBILE = 'one-brand-promo/mobile',
  ONE_BRAND_PROMO_CTV = 'one-brand-promo/ctv',
  ONE_BRAND_PROMO_TABLET = 'one-brand-promo/tablet',
  // profile module
  PROFILE_LANDSCAPE = 'profile/landscape',
  PROFILE_PORTRAIT = 'profile/portrait',
  PROFILE_DESKTOP = 'profile/desktop',
  PROFILE_MOBILE = 'profile/mobile',
  PROFILE_CTV = 'profile/ctv',
  PROFILE_TABLET = 'profile/tablet',
  // reg wall module
  REG_WALL_LANDSCAPE = 'reg-wall/landscape',
  REG_WALL_PORTRAIT = 'reg-wall/portrait',
  REG_WALL_DESKTOP = 'reg-wall/desktop',
  REG_WALL_MOBILE = 'reg-wall/mobile',
  REG_WALL_CTV = 'reg-wall/ctv',
  REG_WALL_TABLET = 'reg-wall/tablet',
  // inline page module
  INLINE_PAGE_PORTRAIT = 'inline-page/portrait',
  INLINE_PAGE_LANDSCAPE = 'inline-page/landscape',
  INLINE_PAGE_MOBILE = 'inline-page/mobile',
  INLINE_PAGE_CTV = 'inline-page/ctv',
  // menu items
  MENU_ITEM_LOGO = 'menuItem',
  // account creation
  ACCOUNT_CREATION_VIX_LOGO = 'account-creation/vix-logo',
  ACCOUNT_CREATION_PARTNER_LOGO = 'account-creation/partner-logo',
  // linking account screen
  LINKING_ACCOUNT_VIX_LOGO = 'linking-account/vix-logo',
  LINKING_ACCOUNT_PARTNER_LOGO = 'linking-account/partner-logo',
  LINKING_ACCOUNT_BACKGROUND = 'linking-account/background',
  // landing promo
  LANDING_PROMO_LANDSCAPE = 'landing-promo/landscape',
  LANDING_PROMO_PORTRAIT = 'landing-promo/portrait',
  LANDING_PROMO_DESKTOP = 'landing-promo/desktop',
  LANDING_PROMO_MOBILE = 'landing-promo/mobile',
  LANDING_PROMO_CTV = 'landing-promo/ctv',
  LANDING_PROMO_TABLET = 'landing-promo/tablet',
  // ai search screen
  AI_SEARCH_BACKGROUND_FILL_IMAGE = 'ai-search/background-fill-image',
  // tds gift cards
  TDS_GIFT_CARDS_LOGO = 'tds-gift-cards/logo',
  TDS_GIFT_CARDS_PARTNER_LOGO = 'tds-gift-cards/partner-logo',
  TDS_GIFT_CARDS_LANDSCAPE = 'tds-gift-cards/background-landscape',
  TDS_GIFT_CARDS_PORTRAIT = 'tds-gift-cards/background-portrait',
  TDS_GIFT_CARDS_DESKTOP = 'tds-gift-cards/background-desktop',
  TDS_GIFT_CARDS_MOBILE = 'tds-gift-cards/background-mobile',
  TDS_GIFT_CARDS_CTV = 'tds-gift-cards/background-ctv',
  TDS_GIFT_CARDS_TABLET = 'tds-gift-cards/background-tablet',
  TDS_GIFT_CARDS_HOW_TO_IMAGE = 'tds-gift-cards/how-to-image',
  TDS_GIFT_CARDS_WELCOME_LANDSCAPE = 'tds-gift-cards/welcome-background-landscape',
  TDS_GIFT_CARDS_WELCOME_PORTRAIT = 'tds-gift-cards/welcome-background-portrait',
  TDS_GIFT_CARDS_WELCOME_DESKTOP = 'tds-gift-cards/welcome-background-desktop',
  TDS_GIFT_CARDS_WELCOME_MOBILE = 'tds-gift-cards/welcome-background-mobile',
  TDS_GIFT_CARDS_WELCOME_CTV = 'tds-gift-cards/welcome-background-ctv',
  TDS_GIFT_CARDS_WELCOME_TABLET = 'tds-gift-cards/welcome-background-tablet',
  // spin premia
  SPIN_PREMIA_VIX_LOGO = 'spin-premia/vix-logo',
  SPIN_PREMIA_PARTNER_LOGO = 'spin-premia/spin-logo',
  SPIN_PREMIA_LANDSCAPE = 'spin-premia/landscape',
  SPIN_PREMIA_PORTRAIT = 'spin-premia/portrait',
  SPIN_PREMIA_DESKTOP = 'spin-premia/desktop',
  SPIN_PREMIA_MOBILE = 'spin-premia/mobile',
  SPIN_PREMIA_CTV = 'spin-premia/ctv',
  SPIN_PREMIA_TABLET = 'spin-premia/tablet',
  // new partner landing
  NEW_PARTNER_LANDING_VIX_LOGO = 'new-partner-landing/vix-logo',
  NEW_PARTNER_LANDING_PARTNER_LOGO = 'new-partner-landing/partner-logo'
}

export const AssetTypes = {
  channel: {
    logo: AssetTypesEnum.CHANNEL_LOGO,
    portrait: AssetTypesEnum.CHANNEL_PORTRAIT,
    landscape: AssetTypesEnum.CHANNEL_LANDSCAPE
  },
  collections: {
    cardImageLandsape: AssetTypesEnum.COLLECTIONS_CARD,
    landscapeSplash: AssetTypesEnum.COLLECTIONS_LANDSCAPE,
    portraitSplash: AssetTypesEnum.COLLECTIONS_PORTRAIT
  },
  hero: {
    landscape: AssetTypesEnum.HERO_LANDSCAPE,
    portrait: AssetTypesEnum.HERO_PORTRAIT,
    mobile: AssetTypesEnum.HERO_MOBILE,
    logo: AssetTypesEnum.HERO_LOGO,
    logoPortrait: AssetTypesEnum.HERO_LOGO_PORTRAIT,
    ctv: AssetTypesEnum.HERO_CTV,
    sponsor: AssetTypesEnum.HERO_SPONSOR
  },
  contentList: {
    landscape: AssetTypesEnum.CONTENT_LIST_LANDSCAPE,
    portrait: AssetTypesEnum.CONTENT_LIST_PORTRAIT
  },
  sports: {
    landscape: AssetTypesEnum.SPORT_LANDSCAPE,
    portrait: AssetTypesEnum.SPORT_PORTRAIT,
    leagueLogo: AssetTypesEnum.LEAGUE_LOGO,
    leagueCard: AssetTypesEnum.LEAGUE_CARD,
    leagueSplash: AssetTypesEnum.LEAGUE_SPLASH,
    teamLogo: AssetTypesEnum.TEAM_LOGO
  },
  sportsDefaultAssets: {
    tournamentBg: AssetTypesEnum.SPORTS_DEFAULT_LEAGUE_BG,
    tournamentSplash: AssetTypesEnum.SPORTS_DEFAULT_LEAGUE_SPLASH,
    tournamentLogo: AssetTypesEnum.SPORTS_DEFAULT_LEAGUE_LOGO,
    teamLogo: AssetTypesEnum.SPORTS_DEFAULT_TEAM_LOGO
  },
  promotion: {
    portraitUpsell: AssetTypesEnum.PROMO_UPSELL_PORTRAIT,
    landscapeUpsell: AssetTypesEnum.PROMO_UPSELL_LANDSCAPE,
    desktopUpsell: AssetTypesEnum.PROMO_UPSELL_DESKTOP,
    mobileUpsell: AssetTypesEnum.PROMO_UPSELL_MOBILE,
    ctvUpsell: AssetTypesEnum.PROMO_UPSELL_CTV,
    tabletUpsell: AssetTypesEnum.PROMO_UPSELL_TABLET
  },
  livePlus: {
    portraitTakeover: AssetTypesEnum.LIVE_PLUS_TAKEOVER_PORTRAIT,
    landscapeTakeover: AssetTypesEnum.LIVE_PLUS_TAKEOVER_LANDSCAPE,
    desktopTakeover: AssetTypesEnum.LIVE_PLUS_TAKEOVER_DESKTOP,
    tabletTakeover: AssetTypesEnum.LIVE_PLUS_TAKEOVER_TABLET,
    mobileTakeover: AssetTypesEnum.LIVE_PLUS_TAKEOVER_MOBILE,
    ctvTakeover: AssetTypesEnum.LIVE_PLUS_TAKEOVER_CTV,
    portraitEndGame: AssetTypesEnum.LIVE_PLUS_END_GAME_PORTRAIT,
    landscapeEndGame: AssetTypesEnum.LIVE_PLUS_END_GAME_LANDSCAPE,
    desktopEndGame: AssetTypesEnum.LIVE_PLUS_END_GAME_DESKTOP,
    tabletEndGame: AssetTypesEnum.LIVE_PLUS_END_GAME_TABLET,
    mobileEndGame: AssetTypesEnum.LIVE_PLUS_END_GAME_MOBILE,
    ctvEndGame: AssetTypesEnum.LIVE_PLUS_END_GAME_CTV
  },
  inlinePromo: {
    portraitFill: AssetTypesEnum.INLINE_PROMO_PORTRAIT,
    landscapeFill: AssetTypesEnum.INLINE_PROMO_LANDSCAPE,
    desktopFill: AssetTypesEnum.INLINE_PROMO_DESKTOP,
    tabletFill: AssetTypesEnum.INLINE_PROMO_TABLET,
    mobileFill: AssetTypesEnum.INLINE_PROMO_MOBILE,
    ctvFill: AssetTypesEnum.INLINE_PROMO_CTV
  },
  accountSuccess: {
    portrait: AssetTypesEnum.ACCOUNT_SUCCESS_PORTRAIT,
    landscape: AssetTypesEnum.ACCOUNT_SUCCESS_LANDSCAPE,
    desktop: AssetTypesEnum.ACCOUNT_SUCCESS_DESKTOP,
    tablet: AssetTypesEnum.ACCOUNT_SUCCESS_TABLET,
    mobile: AssetTypesEnum.ACCOUNT_SUCCESS_MOBILE,
    ctv: AssetTypesEnum.ACCOUNT_SUCCESS_CTV,
    logo: AssetTypesEnum.ACCOUNT_SUCCESS_LOGO
  },
  oneBrandPromo: {
    landscape: AssetTypesEnum.ONE_BRAND_PROMO_LANDSCAPE,
    portrait: AssetTypesEnum.ONE_BRAND_PROMO_PORTRAIT,
    desktop: AssetTypesEnum.ONE_BRAND_PROMO_DESKTOP,
    mobile: AssetTypesEnum.ONE_BRAND_PROMO_MOBILE,
    ctv: AssetTypesEnum.ONE_BRAND_PROMO_CTV,
    tablet: AssetTypesEnum.ONE_BRAND_PROMO_TABLET
  },
  profile: {
    landscape: AssetTypesEnum.PROFILE_LANDSCAPE,
    portrait: AssetTypesEnum.PROFILE_PORTRAIT,
    desktop: AssetTypesEnum.PROFILE_DESKTOP,
    mobile: AssetTypesEnum.PROFILE_MOBILE,
    ctv: AssetTypesEnum.PROFILE_CTV,
    tablet: AssetTypesEnum.PROFILE_TABLET
  },
  regWall: {
    landscape: AssetTypesEnum.REG_WALL_LANDSCAPE,
    portrait: AssetTypesEnum.REG_WALL_PORTRAIT,
    desktop: AssetTypesEnum.REG_WALL_DESKTOP,
    mobile: AssetTypesEnum.REG_WALL_MOBILE,
    ctv: AssetTypesEnum.REG_WALL_CTV,
    tablet: AssetTypesEnum.REG_WALL_TABLET
  },
  inlinePage: {
    portrait: AssetTypesEnum.INLINE_PAGE_PORTRAIT,
    landscape: AssetTypesEnum.INLINE_PAGE_LANDSCAPE,
    mobile: AssetTypesEnum.INLINE_PAGE_MOBILE,
    ctv: AssetTypesEnum.INLINE_PAGE_CTV
  },
  accountCreation: {
    vixLogo: AssetTypesEnum.ACCOUNT_CREATION_VIX_LOGO,
    partnerLogo: AssetTypesEnum.ACCOUNT_CREATION_PARTNER_LOGO
  },
  linkingAccount: {
    vixLogo: AssetTypesEnum.LINKING_ACCOUNT_VIX_LOGO,
    partnerLogo: AssetTypesEnum.LINKING_ACCOUNT_PARTNER_LOGO,
    background: AssetTypesEnum.LINKING_ACCOUNT_BACKGROUND
  },
  landingPromo: {
    landscape: AssetTypesEnum.LANDING_PROMO_LANDSCAPE,
    portrait: AssetTypesEnum.LANDING_PROMO_PORTRAIT,
    desktop: AssetTypesEnum.LANDING_PROMO_DESKTOP,
    mobile: AssetTypesEnum.LANDING_PROMO_MOBILE,
    ctv: AssetTypesEnum.LANDING_PROMO_CTV,
    tablet: AssetTypesEnum.LANDING_PROMO_TABLET
  },
  aiSearch: {
    backgroundFillImage: AssetTypesEnum.AI_SEARCH_BACKGROUND_FILL_IMAGE
  },
  tdsGiftCards: {
    logo: AssetTypesEnum.TDS_GIFT_CARDS_LOGO,
    partnerLogo: AssetTypesEnum.TDS_GIFT_CARDS_PARTNER_LOGO,
    backgroundPortraitFillImage: AssetTypesEnum.TDS_GIFT_CARDS_LANDSCAPE,
    backgroundLandscapeFillImage: AssetTypesEnum.TDS_GIFT_CARDS_PORTRAIT,
    backgroundMobileFillImage: AssetTypesEnum.TDS_GIFT_CARDS_DESKTOP,
    backgroundCtvFillImage: AssetTypesEnum.TDS_GIFT_CARDS_MOBILE,
    backgroundDesktopFillImage: AssetTypesEnum.TDS_GIFT_CARDS_CTV,
    backgroundTabletFillImage: AssetTypesEnum.TDS_GIFT_CARDS_TABLET,
    stepTwoHowToImage: AssetTypesEnum.TDS_GIFT_CARDS_HOW_TO_IMAGE,
    stepFourBackgroundPortraitFillImage: AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_LANDSCAPE,
    stepFourBackgroundLandscapeFillImage: AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_PORTRAIT,
    stepFourBackgroundMobileFillImage: AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_DESKTOP,
    stepFourBackgroundCtvFillImage: AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_MOBILE,
    stepFourBackgroundDesktopFillImage: AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_CTV,
    stepFourBackgroundTabletFillImage: AssetTypesEnum.TDS_GIFT_CARDS_WELCOME_TABLET
  },
  spinPremia: {
    vixLogo: AssetTypesEnum.SPIN_PREMIA_VIX_LOGO,
    spinLogo: AssetTypesEnum.SPIN_PREMIA_PARTNER_LOGO,
    landscape: AssetTypesEnum.SPIN_PREMIA_LANDSCAPE,
    portrait: AssetTypesEnum.SPIN_PREMIA_PORTRAIT,
    desktop: AssetTypesEnum.SPIN_PREMIA_DESKTOP,
    mobile: AssetTypesEnum.SPIN_PREMIA_MOBILE,
    ctv: AssetTypesEnum.SPIN_PREMIA_CTV,
    tablet: AssetTypesEnum.SPIN_PREMIA_TABLET
  },
  newPartnerLanding: {
    vixLogo: AssetTypesEnum.NEW_PARTNER_LANDING_VIX_LOGO,
    partnerLogo: AssetTypesEnum.NEW_PARTNER_LANDING_PARTNER_LOGO
  }
};

export const INITIAL_ASSET_TYPE = AssetTypesEnum.CHANNEL_LOGO;

// record of all the string values to their main category
export const AssetRecord: Record<string, AssetCategory> = Object.keys(AssetTypes).reduce(
  (all, category) => ({
    ...all,
    ...Object.values(AssetTypes[category as AssetCategory]).reduce(
      (types, type) => ({ ...types, [type]: category }),
      {}
    )
  }),
  {}
);

export const isValidType = (type: string): boolean => AssetRecord[type] !== undefined;

export type AssetCategory = keyof typeof AssetTypes;
export type AssetType<T extends AssetCategory = AssetCategory> = keyof (typeof AssetTypes)[T];

export enum AssetFileTypesEnum {
  PNG = 'PNG',
  JPG = 'JPG',
  SVG = 'SVG',
  WEBP = 'WEBP'
}

export type AssetGuideline = {
  preferredAspectRatio?: AspectRatiosEnum;
  preferredFileTypes?: Array<AssetFileTypesEnum>;
  minHeight?: number;
  minWidth?: number;
};

export type AssetGuidelinesType = Partial<Record<AssetTypesEnum, AssetGuideline>>;

export type AssetGuidelineCheckResult = {
  adheres: boolean;
  adheresFileType: boolean;
  adheresWidth: boolean;
  adheresHeight: boolean;
  adheresAspectRatio: boolean;
};
