import { InputLabel, MenuItem, Select } from '@mui/material';
import { startCase } from 'lodash-es';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { LayoutType } from '../../../API';
import { useData } from '../../../data-layer';
import { useLayoutsRouter, useLocales } from '../../../hooks';
import {
  withLayoutsType,
  withSelectedContentPagePath,
  withSelectedMenuPagePath,
  withSelectedPromoPagePath,
  withSelectedSpecialPagePath
} from '../../../state/Layouts';
import { pagePathToParam } from '../../../utils/layouts';
import FormControl from '../../shared/FormControl';

export function LayoutsTypeSelector({
  className,
  ...props
}: {
  className?: string;
  'data-testid'?: string;
}): React.ReactElement {
  const { t } = useLocales();
  const { updateLayoutsRoute } = useLayoutsRouter();
  const layoutsType = useRecoilValue(withLayoutsType);

  const contentPagePath = useRecoilValue(withSelectedContentPagePath);
  const menuPagePath = useRecoilValue(withSelectedMenuPagePath);
  const specialPagePath = useRecoilValue(withSelectedSpecialPagePath);
  const promoPagePath = useRecoilValue(withSelectedPromoPagePath);

  const {
    pages: {
      state: { withIsFetching }
    }
  } = useData();
  const isFetchingPages = useRecoilValue(withIsFetching);

  const pagePaths: Record<LayoutType, string> = {
    [LayoutType.PAGE]: contentPagePath,
    [LayoutType.MENU]: menuPagePath,
    [LayoutType.SPECIAL]: specialPagePath,
    [LayoutType.PROMOS]: promoPagePath
  };

  const onLayoutsTypeChange = (type: LayoutType) => {
    updateLayoutsRoute({ type, pageUrl: pagePathToParam(pagePaths[type]) });
  };

  return (
    <FormControl fullWidth className={className}>
      <InputLabel>{t('layouts.layout_type')}</InputLabel>
      <Select
        fullWidth
        label={t('layouts.layout_type')}
        value={layoutsType}
        disabled={isFetchingPages}
        onChange={({ target: { value } }) => onLayoutsTypeChange(value as LayoutType)}
        data-testid={props['data-testid']}
      >
        {Object.values(LayoutType).map((value) => (
          <MenuItem key={value} value={value}>
            {`${startCase(value.toLowerCase())}s`.replace(/ss$/, 's')}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
