import { useRecoilValue } from 'recoil';
import { LayoutType } from '../../API';
import { AppRoutes } from '../../Routes';
import {
  withLayoutSelectedCountriesParam,
  withLayoutsType,
  withSelectedContentPagePath,
  withSelectedMenuLayoutId,
  withSelectedMenuPagePath,
  withSelectedPageLayoutId,
  withSelectedPromoLayoutId,
  withSelectedPromoPagePath,
  withSelectedSpecialLayoutId,
  withSelectedSpecialPagePath
} from '../../state/Layouts';
import { pagePathToParam } from '../../utils/layouts';

type UseLayoutsRouteReturnType = {
  getLayoutsRoute: (type?: LayoutType, pagePath?: string | undefined) => string;
};

export function useLayoutsRoute(): UseLayoutsRouteReturnType {
  const currentLayoutType = useRecoilValue(withLayoutsType);
  const countries = useRecoilValue(withLayoutSelectedCountriesParam);

  const contentPagePath = useRecoilValue(withSelectedContentPagePath);
  const menuPagePath = useRecoilValue(withSelectedMenuPagePath);
  const specialPagePath = useRecoilValue(withSelectedSpecialPagePath);
  const specialPromoPath = useRecoilValue(withSelectedPromoPagePath);

  const pagePaths: Record<LayoutType, string> = {
    [LayoutType.PAGE]: contentPagePath,
    [LayoutType.MENU]: menuPagePath,
    [LayoutType.SPECIAL]: specialPagePath,
    [LayoutType.PROMOS]: specialPromoPath
  };

  const contentLayoutId = useRecoilValue(withSelectedPageLayoutId);
  const menuLayoutId = useRecoilValue(withSelectedMenuLayoutId);
  const specialLayoutId = useRecoilValue(withSelectedSpecialLayoutId);
  const promoLayoutId = useRecoilValue(withSelectedPromoLayoutId);

  const layoutIds: Record<LayoutType, string> = {
    [LayoutType.PAGE]: contentLayoutId,
    [LayoutType.MENU]: menuLayoutId,
    [LayoutType.SPECIAL]: specialLayoutId,
    [LayoutType.PROMOS]: promoLayoutId
  };

  const getLayoutsRoute = (type: LayoutType = currentLayoutType, pagePath?: string | undefined) => {
    return AppRoutes.layouts({
      type,
      countries,
      layoutId: layoutIds[type],
      pageUrl: pagePathToParam(pagePath || pagePaths[type])
    });
  };

  return { getLayoutsRoute };
}
