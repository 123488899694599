import React, { useEffect, useMemo, useState } from 'react';
import { useLocales, useTextFilter, useMatches } from '../../../hooks';
import { InputAdornment } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Search } from '@mui/icons-material';
import TextField from '../../shared/TextField';
import { MediaResponse, PaginationMeta } from '../../../API';
import { useRecoilValue } from 'recoil';
import { withAllMatches, withFetchingMatches } from '../../../state/Sports';
import { withSelectedLayoutCountries } from '../../../state/Layouts';
import { MatchList } from '../../Sports/MatchList';
import Pagination from '../../shared/Pagination';
import { SportOptionsMultiselect } from '../../Layouts/UIModuleForm/UIModules/SportsEventForm/SportsOptionsMultiselect';

const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    padding: theme.spacing(4, 4, 0)
  },
  filtersContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    padding: theme.spacing(0, 4, 0),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  searchBar: {
    width: '100%',
    minWidth: 280,
    marginTop: theme.spacing(1)
  },
  footer: {
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
    padding: theme.spacing(3, 4)
  }
}));

export const testIds = {
  matchFilter: 'match-picker.match-filter',
  matchList: 'live-search.match-list'
};

function LiveSearch(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const matches = useRecoilValue(withAllMatches);
  const fetchingMatches = useRecoilValue(withFetchingMatches);
  const countries = useRecoilValue(withSelectedLayoutCountries);
  const { onFilterInputChange, clearFilter, filteredResults, filterRegExp } = useTextFilter([
    'title',
    'contentId',
    { field: 'sportsEvent' }
  ]);
  const [filteredMatches, setFilteredMatches] = useState<MediaResponse[]>([]);
  const { getAllUpcomingMatches } = useMatches();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [sportIds, setSportIds] = useState([] as string[]);
  const [leagueIds, setLeagueIds] = useState([] as string[]);
  const [matchesPaginated, setMatchesPaginated] = useState<MediaResponse[]>([]);

  useEffect(() => {
    getAllUpcomingMatches(countries);
  }, []);

  // TODO: Remove this logic when the LiveSearch pagination gets reworked
  const filterSportsAndLeagues = (matches: MediaResponse[]) => {
    return matches.filter(({ tournament }) => {
      if (tournament) {
        if (leagueIds.length && sportIds.length) {
          return leagueIds.includes(tournament.tournamentId) && sportIds.includes(tournament.sport);
        }
        if (leagueIds.length) return leagueIds.includes(tournament.tournamentId);
        if (sportIds.length) return sportIds.includes(tournament.sport);
        return true;
      }
      if (!tournament && (leagueIds.length || sportIds.length)) return false;
      return true;
    });
  };

  useEffect(() => {
    if (matches) {
      const filteredMatches = filterSportsAndLeagues(matches);
      setFilteredMatches(filteredResults(filteredMatches) as MediaResponse[]);
      setPage(1);
    }
  }, [matches, filterRegExp, sportIds, leagueIds]);

  const getQueryMeta: PaginationMeta = useMemo(() => {
    return {
      totalDocs: filteredMatches?.length || 0,
      limit,
      page,
      totalPages: Math.ceil((filteredMatches?.length || 0) / limit),
      nextPage: page + 1,
      prevPage: page - 1,
      pagingCounter: page,
      hasNextPage: page < Math.ceil((filteredMatches?.length || 0) / limit),
      hasPrevPage: page > 1
    };
  }, [filteredMatches, limit, page]);

  useEffect(() => {
    if (filteredMatches) {
      setMatchesPaginated(filteredMatches.slice((page - 1) * limit, page * limit));
    }
  }, [limit, page, filteredMatches]);

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.header}>
          <TextField
            onChange={onFilterInputChange}
            className={classes.searchBar}
            label={t('sports.browse_matches')}
            disabled={!matches}
            clearable
            onClear={clearFilter}
            data-testid={testIds.matchFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={classes.filtersContainer}>
          <SportOptionsMultiselect
            sports={sportIds}
            leagues={leagueIds}
            onSportsChange={(sports) => setSportIds(sports)}
            onLeaguesChange={(leagues) => setLeagueIds(leagues)}
          />
        </div>
        <MatchList matches={matchesPaginated || []} loading={fetchingMatches} data-testid={testIds.matchList} />
        <div className={classes.footer}>
          <Pagination
            queryMetaData={getQueryMeta}
            onPageChange={(page: number) => {
              setPage(page);
            }}
            onResultsChange={(limit: number) => {
              setLimit(limit);
              setPage(1);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default LiveSearch;
