import {
  DEFAULT_LAYOUTS_MENU_URL,
  DEFAULT_LAYOUTS_PAGE_URL,
  DEFAULT_LAYOUTS_PROMO_URL,
  DEFAULT_LAYOUTS_SPECIAL_URL
} from '../../utils/appDefaults';
import { localAtom } from '../localStorageState';

export const withSelectedContentPagePath = localAtom({
  key: 'layouts.selectedContentPagePath',
  default: DEFAULT_LAYOUTS_PAGE_URL
});

export const withSelectedMenuPagePath = localAtom({
  key: 'layouts.selectedMenuPath',
  default: DEFAULT_LAYOUTS_MENU_URL
});

export const withSelectedSpecialPagePath = localAtom({
  key: 'layouts.selectedSpecialPagePath',
  default: DEFAULT_LAYOUTS_SPECIAL_URL
});

export const withSelectedPromoPagePath = localAtom({
  key: 'layouts.selectedPromoPagePath',
  default: DEFAULT_LAYOUTS_PROMO_URL
});
